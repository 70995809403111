export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_EMAIL_FILTER = "SET_EMAIL_FILTER";
export const SET_MAILBOX_FILTER = "SET_MAILBOX_FILTER";
export const SET_MAILBOX_DATA = "SET_MAILBOX_DATA";
export const SET_SWITCH_MAIL_STATUS = "SET_SWITCH_MAIL_STATUS";
export const SET_SELECTED_TARGET_EMAILS = "SET_SELECTED_TARGET_EMAILS";
export const SET_SELECTED_EMAIL_CONTENT = "SET_SELECTED_EMAIL_CONTENT";
export const SET_UPDATE_MAILBOX_LIST = "SET_UPDATE_MAILBOX_LIST";
export const SET_TOTAL_ITEMS = "SET_TOTAL_ITEMS";
export const SET_EMAIL_TEMPLATES = "SET_EMAIL_TEMPLATES";

export const SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_ERROR = "SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_ERROR";
export const SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_SUCCESS = "SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_SUCCESS";
export const SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING = "SET_FORCE_SYNC_BY_EMAIL_CONFIG_IDS_LOADING";
export const SET_EMAIL_TEMPLATES_BY_CATEGORY = "SET_EMAIL_TEMPLATES";
export const SET_SELECTED_EMAIL_TEMPLATE = "SET_SELECTED_EMAIL_TEMPLATE";
export const SET_TEMPLATE_CATEGORIES = "SET_TEMPLATE_CATEGORIES";
export const SET_PLACEHOLDERS = "SET_PLACEHOLDERS";
export const SET_STUDENT = "SET_STUDENT";
export const SET_BLOB = "SET_BLOB";
export const SET_MIME_TYPE = "SET_MIME_TYPE";
export const SET_COST_BEARER = "SET_COST_BEARER";
export const SET_ATTACH_EXAM_DOCUMENT = "SET_ATTACH_EXAM_DOCUMENT";
