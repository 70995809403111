import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_DATA_WORK_HOURS, SET_SUCCESS } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import type { IDayWorkHour } from "@/interfaces/IDayWorkHour";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import queryString from "query-string";
import { findAll, create, findOne, update, filter } from "@/store/Crud/functions/actions";
import { archive } from "@/store/Crud/functions/archived/actions";

const URL = "/branches";
const URL_WORK_HOURS = "/branch-work-hours";

export const workHours = async (store: any, branchId: number): Promise<void> => {
  return await axios
    .get(`${URL_WORK_HOURS}/${branchId}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_WORK_HOURS, data.dayWorkHours);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const workHoursUpdate = async (store: any, data: IDayWorkHour): Promise<void> => {
  return await axios
    .put(`${URL_WORK_HOURS}/${data.id}`, data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      // store.commit(SET_DATA_WORK_HOURS, data.dayWorkHours);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

// export const archive = async (store: any, data: any): Promise<void> => {
//   const queryBuilder = queryString.stringify(data);
//
//   return await axios
//     .post(`${URL}/archive?${queryBuilder}`)
//     .then(({ data }) => {
//       store.commit(IS_LOADING, false);
//       // store.commit(SET_DATA_ITEM, data);
//       store.commit(SET_SUCCESS, true);
//     })
//     .catch(({ response }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_ERROR, response.data);
//     });
// };

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  workHours,
  workHoursUpdate,
  archive,
  findAll,
};
