import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getDocumentsInProcessing = (state: State) => state.documentsInProcessing;
export const getDocumentsInProcessingError = (state: State) => state.documentsInProcessingError;
export const getDocumentsInProcessingSuccess = (state: State) => state.documentsInProcessingSuccess;
export const getDocumentsInProcessingLoading = (state: State) => state.documentsInProcessingLoading;

export const getDocumentsInProcessingDeleteError = (state: State) => state.documentsInProcessingDeleteError;
export const getDocumentsInProcessingDeleteSuccess = (state: State) => state.documentsInProcessingDeleteSuccess;
export const getDocumentsInProcessingDeleteLoading = (state: State) => state.documentsInProcessingDeleteLoading;

export const getAttachDocumentsInProcessingError = (state: State) => state.attachDocumentsInProcessingError;
export const getAttachDocumentsInProcessingSuccess = (state: State) => state.attachDocumentsInProcessingSuccess;
export const getAttachDocumentsInProcessingLoading = (state: State) => state.attachDocumentsInProcessingLoading;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getDocumentsInProcessing,
  getDocumentsInProcessingError,
  getDocumentsInProcessingSuccess,
  getDocumentsInProcessingLoading,
  getDocumentsInProcessingDeleteError,
  getDocumentsInProcessingDeleteSuccess,
  getDocumentsInProcessingDeleteLoading,
  getAttachDocumentsInProcessingError,
  getAttachDocumentsInProcessingSuccess,
  getAttachDocumentsInProcessingLoading,
};
