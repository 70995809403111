import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import {
  SET_ATTACH_DOCUMENTS_IN_PROCESSING_ERROR,
  SET_ATTACH_DOCUMENTS_IN_PROCESSING_LOADING,
  SET_ATTACH_DOCUMENTS_IN_PROCESSING_SUCCESS,
  SET_DOCUMENTS_IN_PROCESSING,
  SET_DOCUMENTS_IN_PROCESSING_DELETE_ERROR,
  SET_DOCUMENTS_IN_PROCESSING_DELETE_LOADING,
  SET_DOCUMENTS_IN_PROCESSING_DELETE_SUCCESS,
  SET_DOCUMENTS_IN_PROCESSING_ERROR,
  SET_DOCUMENTS_IN_PROCESSING_LOADING,
  SET_DOCUMENTS_IN_PROCESSING_SUCCESS,
} from "@/store/Document/types";
import { IDocument } from "@/interfaces/IDocument";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_DOCUMENTS_IN_PROCESSING_ERROR](state: State, documentsInProcessingError: Record<any, any> | null) {
    state.documentsInProcessingError = documentsInProcessingError;
  },

  [SET_DOCUMENTS_IN_PROCESSING_SUCCESS](state: State, documentsInProcessingSuccess: boolean) {
    state.documentsInProcessingSuccess = documentsInProcessingSuccess;
  },

  [SET_DOCUMENTS_IN_PROCESSING_LOADING](state: State, documentsInProcessingLoading: boolean) {
    state.documentsInProcessingLoading = documentsInProcessingLoading;
  },

  [SET_DOCUMENTS_IN_PROCESSING](state: State, documentsInProcessing: Array<IDocument>) {
    state.documentsInProcessing = documentsInProcessing;
  },

  [SET_DOCUMENTS_IN_PROCESSING_DELETE_ERROR](state: State, documentsInProcessingDeleteError: Record<any, any> | null) {
    state.documentsInProcessingDeleteError = documentsInProcessingDeleteError;
  },

  [SET_DOCUMENTS_IN_PROCESSING_DELETE_SUCCESS](state: State, documentsInProcessingDeleteSuccess: boolean) {
    state.documentsInProcessingDeleteSuccess = documentsInProcessingDeleteSuccess;
  },

  [SET_DOCUMENTS_IN_PROCESSING_DELETE_LOADING](state: State, documentsInProcessingDeleteLoading: boolean) {
    state.documentsInProcessingDeleteLoading = documentsInProcessingDeleteLoading;
  },

  [SET_ATTACH_DOCUMENTS_IN_PROCESSING_ERROR](state: State, attachDocumentsInProcessingError: Record<any, any> | null) {
    state.attachDocumentsInProcessingError = attachDocumentsInProcessingError;
  },

  [SET_ATTACH_DOCUMENTS_IN_PROCESSING_SUCCESS](state: State, attachDocumentsInProcessingSuccess: boolean) {
    state.attachDocumentsInProcessingSuccess = attachDocumentsInProcessingSuccess;
  },

  [SET_ATTACH_DOCUMENTS_IN_PROCESSING_LOADING](state: State, attachDocumentsInProcessingLoading: boolean) {
    state.attachDocumentsInProcessingLoading = attachDocumentsInProcessingLoading;
  },
};
