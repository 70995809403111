import { State } from "./state";
import { GetterTree } from "vuex";
import { getDataList, getDataItem, getSuccess, getId } from "@/store/Crud/functions/getters";
import {
  getStudentList,
  getStudentListError,
  getStudentListSuccess,
  getStudentListLoading,
  getOverviewInfo,
  getOverviewInfoError,
  getOverviewInfoSuccess,
  getOverviewInfoLoading,
  getStudentListTotal,
} from "@/store/PaymentWorkflow/getters";

export const getLivePayPaymentUpdateStatusLoading = (state: State) => state.livePayPaymentUpdateStatusLoading;
export const getLivePayPaymentUpdateStatusSuccess = (state: State) => state.livePayPaymentUpdateStatusSuccess;
export const getLivePayPaymentUpdateStatusError = (state: State) => state.livePayPaymentUpdateStatusError;

export default <GetterTree<State, any>>{
  getDataList,
  getStudentList,
  getStudentListError,
  getStudentListSuccess,
  getStudentListLoading,
  getOverviewInfo,
  getOverviewInfoError,
  getOverviewInfoSuccess,
  getOverviewInfoLoading,
  getLivePayPaymentUpdateStatusLoading,
  getLivePayPaymentUpdateStatusSuccess,
  getLivePayPaymentUpdateStatusError,
  getDataItem,
  getSuccess,
  getStudentListTotal,
  getId,
};
