import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  SET_DATA_DELETED,
} from "@/store/Crud/functions/mutations";

export function SET_COURSES_AND_THEORY_LESSONS(state: State, coursesAndTheoryLessons: Array<any>) {
  state.coursesAndTheoryLessons = coursesAndTheoryLessons;
}

export function SET_UPCOMING_THEORY_COURSES(state: State, upcomingTheoryCourses: Array<any>) {
  state.upcomingTheoryCourses = upcomingTheoryCourses;
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_COURSES_AND_THEORY_LESSONS,
  SET_DATA_DELETED,
  SET_DATA_ITEM_LOADING,
  SET_UPCOMING_THEORY_COURSES,
};
