import { State } from "./state";
import { GetterTree } from "vuex";
import { getDataList, getSuccess, getIsLoading } from "@/store/Crud/functions/getters";

export const getMandateRevokeLoading = (state: State) => state.mandateRevokeLoading;
export const getMandateRevokeError = (state: State) => state.mandateRevokeError;
export const getMandateRevokeSuccess = (state: State) => state.mandateRevokeSuccess;
export const getMandateRevokeResponse = (state: State) => state.mandateRevokeResponse;

export const getMandateByStudentEducationLoading = (state: State) => state.mandateByStudentEducationLoading;
export const getMandateByStudentEducationError = (state: State) => state.mandateByStudentEducationError;
export const getMandateByStudentEducationSuccess = (state: State) => state.mandateByStudentEducationSuccess;
export const getMandateByStudentEducationResponse = (state: State) => state.mandateByStudentEducationResponse;

export const getMandateByStudentLoading = (state: State) => state.mandateByStudentLoading;
export const getMandateByStudentError = (state: State) => state.mandateByStudentError;
export const getMandateByStudentSuccess = (state: State) => state.mandateByStudentSuccess;
export const getMandateByStudentResponse = (state: State) => state.mandateByStudentResponse;

export default <GetterTree<State, any>>{
  getDataList,
  getSuccess,
  getIsLoading,
  getMandateRevokeLoading,
  getMandateRevokeError,
  getMandateRevokeSuccess,
  getMandateRevokeResponse,
  getMandateByStudentEducationLoading,
  getMandateByStudentEducationError,
  getMandateByStudentEducationSuccess,
  getMandateByStudentEducationResponse,
  getMandateByStudentLoading,
  getMandateByStudentError,
  getMandateByStudentSuccess,
  getMandateByStudentResponse,
};
