import type { IDayWorkHour } from "@/interfaces/IDayWorkHour";
import { CrudState } from "@/store/Crud/State";
import { IStudentQuestionAnswerMetrics } from "@/interfaces/IStudentQuestionAnswerMetrics";
import { IStudentQuestionAnswerStats } from "@/interfaces/IStudentQuestionAnswerStats";

export class State extends CrudState {
  workHours: Array<IDayWorkHour> = [];

  studentQuestionAnswerMetrics: IStudentQuestionAnswerMetrics | null = null;
  studentQuestionAnswerMetricsLoading = false;
  studentQuestionAnswerMetricsSuccess = false;
  studentQuestionAnswerMetricsError: Record<any, any> | null = null;

  studentQuestionAnswerStats: IStudentQuestionAnswerStats | null = null;
  studentQuestionAnswerStatsLoading = false;
  studentQuestionAnswerStatsSuccess = false;
  studentQuestionAnswerStatsError: Record<any, any> | null = null;
}
