import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_LIST, SET_DATA_ITEM_LOADING, SET_TOTAL } from "@/store/Crud/functions/mutations";
import { SET_CANCEL_INVOICE, SET_CANCEL_INVOICE_ERROR, SET_CANCEL_INVOICE_LOADING, SET_CANCEL_INVOICE_SUCCESS } from "@/store/PaymentWorkflow/mutations";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_CANCEL_INVOICE,
  SET_CANCEL_INVOICE_ERROR,
  SET_CANCEL_INVOICE_LOADING,
  SET_CANCEL_INVOICE_SUCCESS,
};
