import { ExamState } from "@/store/Exam/State";
import i18n from "@/i18n";

export function SET_BOOKING_SUCCESS(state: ExamState, bookingSuccess: any) {
  state.bookingSuccess = bookingSuccess;
}

export function IS_BOOKING_LOADING(state: ExamState, bookingLoading: any) {
  state.bookingLoading = bookingLoading;
}

export function SET_UPLOAD_SUCCESS(state: ExamState, uploadSuccess: any) {
  state.uploadSuccess = uploadSuccess;
}

export function IS_UPLOAD_LOADING(state: ExamState, uploadLoading: any) {
  state.uploadLoading = uploadLoading;
}

export function SET_EXAM_STATUS_SUCCESS(state: ExamState, examStatusSuccess: any) {
  state.examStatusSuccess = examStatusSuccess;
}

export function IS_EXAM_STATUS_LOADING(state: ExamState, examStatusLoading: any) {
  state.examStatusLoading = examStatusLoading;
}

export function SET_PARTICIPANT_LIST_SUCCESS(state: ExamState, participantsListSuccess: any) {
  state.participantsListSuccess = participantsListSuccess;
}

export function IS_PARTICIPANT_LIST_LOADING(state: ExamState, participantsListLoading: any) {
  state.participantsListLoading = participantsListLoading;
}

export function SET_PORTION_SUCCESS(state: ExamState, portionSuccess: any): void {
  state.portionSuccess = portionSuccess;
}

export function SET_PORTION_LOADING(state: ExamState, portionIsLoading: boolean): void {
  state.portionIsLoading = portionIsLoading;
}

export function SET_PORTION_DATA_LIST(state: ExamState, portionDataList: Array<any>): void {
  state.portionDataList = portionDataList;
}

export function SET_PORTION_ERROR(state: ExamState, portionError: any): void {
  state.portionError = portionError;
}

export function SET_READY_SUCCESS(state: ExamState, readySuccess: any): void {
  state.readySuccess = readySuccess;
}

export function SET_READY_ERROR(state: ExamState, readyError: any): void {
  state.readyError = readyError;
}

export function SET_READY_LOADING(state: ExamState, readyIsLoading: any): void {
  state.readyIsLoading = readyIsLoading;
}

export function SET_REMOVE_PARTICIPANT_SUCCESS(state: ExamState, removeParticipntSuccess: any): void {
  state.removeParticipantSuccess = removeParticipntSuccess;
}
export function SET_EDUCATION_STATUS_INFO(state: ExamState, educationStatusInfoData: any): void {
  const { requirementsToFulfillmentMap, studentEducationId } = educationStatusInfoData;

  const info: any = {
    studentEducationId,
    requirementsToFulfillmentMap: [],
  };
  if (Object.keys(requirementsToFulfillmentMap).length > 0) {
    Object.keys(requirementsToFulfillmentMap).forEach((key) => {
      info.requirementsToFulfillmentMap.push({
        name: key,
        status: requirementsToFulfillmentMap[key],
      });
    });
  } else {
    const message = i18n.t("general.no_data");
    info.requirementsToFulfillmentMap.push({ name: message, status: true });
  }
  state.educationStatusInfoData = info;
}
