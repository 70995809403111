import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getTemplates = (state: State) => state.templates;
export const getTemplatesByCategory = (state: State) => state.templatesByCategory;

export const getSelectedTemplate = (state: State) => state.selectedTemplate;
export const getTemplateCategories = (state: State) => state.templateCategories;
export const getPlaceholders = (state: State) => state.placeholders;
export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getId,
  getTemplates,
  getTemplatesByCategory,
  getSelectedTemplate,
  getTemplateCategories,
  getPlaceholders,
};
