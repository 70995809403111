export const SET_UPLOAD_STUDENT_SIGNATURE_ERROR = "SET_UPLOAD_STUDENT_SIGNATURE_ERROR";
export const SET_UPLOAD_STUDENT_SIGNATURE_SUCCESS = "SET_UPLOAD_STUDENT_SIGNATURE_SUCCESS";
export const SET_UPLOAD_STUDENT_SIGNATURE_LOADING = "SET_UPLOAD_STUDENT_SIGNATURE_LOADING";

export const SET_UPLOAD_PARENT_SIGNATURE_ERROR = "SET_UPLOAD_PARENT_SIGNATURE_ERROR";
export const SET_UPLOAD_PARENT_SIGNATURE_SUCCESS = "SET_UPLOAD_PARENT_SIGNATURE_SUCCESS";
export const SET_UPLOAD_PARENT_SIGNATURE_LOADING = "SET_UPLOAD_PARENT_SIGNATURE_LOADING";

export const SET_SIGNED_LOADING = "SET_SIGNED_LOADING";
export const SET_SIGNED_SUCCESS = "SET_SIGNED_SUCCESS";
export const SET_SIGNED_ERROR = "SET_SIGNED_ERROR";
export const SET_SIGNED_RESPONSE = "SET_SIGNED_RESPONSE";

export const SET_ADDONS_CHECKBOX_TEXT_LOADING = "SET_ADDONS_CHECKBOX_TEXT_LOADING";
export const SET_ADDONS_CHECKBOX_TEXT_SUCCESS = "SET_ADDONS_CHECKBOX_TEXT_SUCCESS";
export const SET_ADDONS_CHECKBOX_TEXT_ERROR = "SET_ADDONS_CHECKBOX_TEXT_ERROR";
export const SET_ADDONS_CHECKBOX_TEXT_RESPONSE = "SET_ADDONS_CHECKBOX_TEXT_RESPONSE";

export const SET_GDPR_URL_CHECKBOX_TEXT_LOADING = "SET_GDPR_URL_CHECKBOX_TEXT_LOADING";
export const SET_GDPR_URL_CHECKBOX_TEXT_SUCCESS = "SET_GDPR_URL_CHECKBOX_TEXT_SUCCESS";
export const SET_GDPR_URL_CHECKBOX_TEXT_ERROR = "SET_GDPR_URL_CHECKBOX_TEXT_ERROR";
export const SET_GDPR_URL_CHECKBOX_TEXT_RESPONSE = "SET_GDPR_URL_CHECKBOX_TEXT_RESPONSE";
