import { GetterTree } from "vuex";
import { CrudState } from "@/store/Crud/State";

export const getError = (state: CrudState) => state.error;
export const getSuccess = (state: CrudState) => state.success;
export const getIsLoading = (state: CrudState) => state.isLoading;
export const getDataItem = (state: CrudState) => state.dataItem;
export const getDataList = (state: CrudState) => state.dataList;
export const getTotal = (state: CrudState) => state.total;
export const getId = (state: CrudState) => state.id;
export const getDeleted = (state: CrudState) => state.deleted;

export const getFileSuccess = (state: CrudState) => state.fileSuccess;
export const getFileError = (state: CrudState) => state.fileError;
export const getFileLoading = (state: CrudState) => state.fileLoading;
export const getFileResponse = (state: CrudState) => state.fileResponse;

export const getDataItemLoading = (state: CrudState) => state.dataItemLoading;
export const getSelectedRowName = (state: CrudState) => state.selectedRowName;

export default <GetterTree<CrudState, any>>{
  getDataList,
  getTotal,
  getDataItem,
  getError,
  getIsLoading,
  getSuccess,
  getId,
  getDeleted,
  getFileSuccess,
  getFileError,
  getFileResponse,
  getFileLoading,
  getDataItemLoading,
};
