import { CrudState } from "@/store/Crud/State";

export class State extends CrudState {
  uploadStudentSignatureError: Record<any, any> | null = null;
  uploadStudentSignatureSuccess = false;
  uploadStudentSignatureLoading = false;

  uploadParentSignatureError: Record<any, any> | null = null;
  uploadParentSignatureSuccess = false;
  uploadParentSignatureLoading = false;

  signedError: Record<any, any> | null = null;
  signedSuccess = false;
  signedLoading = false;
  signedResponse: null | File = null;

  addonsCheckboxTextError: Record<any, any> | null = null;
  addonsCheckboxTextSuccess = false;
  addonsCheckboxTextLoading = false;
  addonsCheckboxTextResponse: null | string = null;

  gdprUrlCheckboxTextError: Record<any, any> | null = null;
  gdprUrlCheckboxTextSuccess = false;
  gdprUrlCheckboxTextLoading = false;
  gdprUrlCheckboxTextResponse: null | string = null;
}
