import { State } from "./state";
import { MutationTree } from "vuex";
import {
  SET_COMMUNICATION_BADGE,
  SET_COMMUNICATION_BADGE_LOADING,
  SET_COMMUNICATION_BADGE_SUCCESS,
  SET_EMAIL_BADGE,
  SET_REGISTRATION_BADGE,
} from "@/store/Communication/types";

export default <MutationTree<State>>{
  [SET_REGISTRATION_BADGE](state: State, registrationBadge: number) {
    Object.assign(state, { registrationBadge });
  },
  [SET_EMAIL_BADGE](state: State, emailBadge: number) {
    Object.assign(state, { emailBadge });
  },
  [SET_COMMUNICATION_BADGE](state: State, communicationBadge: boolean) {
    Object.assign(state, { communicationBadge });
  },
  [SET_COMMUNICATION_BADGE_LOADING](state: State, registrationBadgeLoading: boolean) {
    Object.assign(state, { registrationBadgeLoading });
  },
  [SET_COMMUNICATION_BADGE_SUCCESS](state: State, registrationBadgeSuccess: boolean) {
    Object.assign(state, { registrationBadgeSuccess });
  },
};
