import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import {
  SET_VEHICLE_BY_CURRENT_INSTRUCTOR,
  SET_VEHICLE_BY_INSTRUCTOR_AND_USAGE_AND_LICENSE_CLASS,
  SET_VEHICLE_USAGE_REQUIREMENT_BY_LICENSE_CLASS,
} from "./types";
import { SET_ARCHIVE_SUCCESS } from "@/store/Crud/functions/archived/mutations";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ARCHIVE_SUCCESS,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  [SET_VEHICLE_USAGE_REQUIREMENT_BY_LICENSE_CLASS](state: State, vehicleUsageRequirementsByLicenseClass: Array<any>) {
    Object.assign(state, { vehicleUsageRequirementsByLicenseClass });
  },
  [SET_VEHICLE_BY_INSTRUCTOR_AND_USAGE_AND_LICENSE_CLASS](state: State, vehiclesByInstructorAndUsageAndLicenseClass: Array<any>) {
    Object.assign(state, { vehiclesByInstructorAndUsageAndLicenseClass });
  },
  [SET_VEHICLE_BY_CURRENT_INSTRUCTOR](state: State, vehiclesByCurrentInstructor: Array<any>) {
    Object.assign(state, { vehiclesByCurrentInstructor });
  },
};
