import { CrudState } from "@/store/Crud/State";
import { IEmailTemplateCategory } from "@/interfaces/IEmailTemplateCategory";
import { IEmailTemplate } from "@/interfaces/IEmailTemplate";

export class State extends CrudState {
  resource: any = "";
  selectedEmailContent: any = {};
  templateCategories: Array<any> = [];
  templatesByCategory: Array<any> = [
    {
      id: 1,
      name: "Template 1",
      subject: "Template 1 Subject",
      body: "<p><strong>Title</strong>Template 1 </p><p>Body</p>",
      templateCategoryId: 0,
    },
  ];

  selectedTemplate: any = {};
  placeholders: Array<any> = [];
  templates: Array<IEmailTemplate> = [];
}
