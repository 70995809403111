import { create, filter, findOne, update, findAll, del } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import { AxiosError } from "axios";
import { ActionTree } from "vuex";
import { IS_LOADING, SET_TASK_STATUSES, SET_TASK_GROUPS, SET_TASK_PRIORITIES, SET_SUCCESS, SET_ERROR, SET_DATA_ITEM } from "./types";
import { State } from "./state";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import i18n from "@/i18n";

const messageDispatcher = new MessageDispatcher();
const URL = "/todos";

export const uploadAttachment = async (store: any, options: any): Promise<void> => {
  store.commit(SET_SUCCESS, null);
  return await axios
    .post(`${URL}/${options.todoId}/attachment`, options.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error);
      store.commit(SET_SUCCESS, false);
    });
};

export const findStatuses = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`/task-statuses`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_TASK_STATUSES, data);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_ERROR", error);
    });
};

export const findPriorities = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`/task-priorities`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_TASK_PRIORITIES, data);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_ERROR", error);
    });
};

export const findGroups = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`/task-groups`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_TASK_GROUPS, data);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_ERROR", error);
    });
};

export const updateStatusOrGroup = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .put(options.resource, options.data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(() => {
      store.commit(IS_LOADING, false);
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.save_success", {
            item: null,
          })
        )
      );
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_ERROR", error);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  del,
  findAll,
  findGroups,
  findPriorities,
  findStatuses,
  updateStatusOrGroup,
  uploadAttachment,
};
