import { State } from "./state";
import { GetterTree } from "vuex";

export const getCommunicationBadge = (state: State) => state.communicationBadge;
export const getRegistrationBadge = (state: State) => state.registrationBadge;
export const getEmailBadge = (state: State) => state.emailBadge;
export const getRegistrationBadgeLoading = (state: State) => state.registrationBadgeLoading;
export const getRegistrationBadgeSuccess = (state: State) => state.registrationBadgeSuccess;

export default <GetterTree<State, any>>{
  getCommunicationBadge,
  getRegistrationBadge,
  getEmailBadge,
  getRegistrationBadgeLoading,
  getRegistrationBadgeSuccess,
};
