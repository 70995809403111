export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_SELECTED_EMAIL_CONTENT = "SET_SELECTED_EMAIL_CONTENT";
export const SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE";
export const SET_TEMPLATE_CATEGORIES = "SET_TEMPLATE_CATEGORIES";
export const SET_PLACEHOLDERS = "SET_PLACEHOLDERS";
export const SET_TEMPLATES_BY_CATEGORY = "SET_TEMPLATES_BY_CATEGORY";
export const SET_TEMPLATES = "SET_TEMPLATES";
