import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_DATA_LIST, SET_DATA_ITEM, SET_ERROR, SET_SUCCESS } from "./types";
import axios from "@/utils/axios";
import { Vue } from "vue-property-decorator";
import i18n from "@/i18n";

const URL = "/request-fsc-user-password-change";
const SET_URL = "/set-fsc-user-password";

export const requestUserPasswordChange = async (store: any, email: any): Promise<void> => {
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}?userEmail=${email}`)
    .then(({ data }) => {
      store.commit(SET_SUCCESS, true);
      Vue.toasted.success(String(i18n.t("password.instruction")));
    })
    .catch(({ response }) => {
      store.commit(SET_SUCCESS, false);
      store.commit(SET_ERROR, response.data);
      if (response.status === 404) {
        Vue.toasted.error(String(i18n.t("password.no_user")));
      }
    });
};

export const setUserPassword = async (store: any, options: any): Promise<void> => {
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${SET_URL}?passwordToken=${options.passwordToken}&rawPassword=${options.rawPassword}`)
    .then(({ data }) => {
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(SET_SUCCESS, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
  requestUserPasswordChange,
  setUserPassword,
};
