export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_QUESTION_VERSION = "SET_QUESTION_VERSION";
export const SET_DRIVE_BUZZ = "SET_DRIVE_BUZZ";

export const FINISHED_EDUCATION_STATE_SUCCESS = "FINISHED_EDUCATION_STATE_SUCCESS";
export const FINISHED_EDUCATION_STATE_ERROR = "FINISHED_EDUCATION_STATE_ERROR";
export const FINISHED_EDUCATION_STATE_IS_LOADING = "FINISHED_EDUCATION_STATE_IS_LOADING";

export const AUTOMATIC_PAYMENT_STATE_SUCCESS = "AUTOMATIC_PAYMENT_STATE_SUCCESS";
export const AUTOMATIC_PAYMENT_STATE_ERROR = "AUTOMATIC_PAYMENT_STATE_ERROR";
export const AUTOMATIC_PAYMENT_STATE_IS_LOADING = "AUTOMATIC_PAYMENT_STATE_IS_LOADING";

export const SET_DATA_ITEM_COST_BEARER = "SET_DATA_ITEM_COST_BEARER";
