import { ActionTree } from "vuex";
import { State } from "./state";
import { IS_LOADING, SET_ERROR, SET_DATA_ITEM, SET_SUCCESS, SET_MIME_TYPE, SET_DATA_LIST, SET_DATA_ITEM_BLOB } from "./types";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import { AxiosResponse } from "axios";

const URL = "/student-documents";

export const findStudentDocument = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL}/${id}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM_BLOB, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const generateProofOfTrainingPreview = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "post",
    url: `/proof-of-training/preview?studentEducationId=${options.studentEducationId}&issueDate=${options.issueDate}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  findStudentDocument,
  generateProofOfTrainingPreview,
};
