import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import {
  AUTOMATIC_PAYMENT_STATE_ERROR,
  AUTOMATIC_PAYMENT_STATE_IS_LOADING,
  AUTOMATIC_PAYMENT_STATE_SUCCESS,
  FINISHED_EDUCATION_STATE_ERROR,
  FINISHED_EDUCATION_STATE_IS_LOADING,
  FINISHED_EDUCATION_STATE_SUCCESS,
  SET_QUESTION_VERSION,
  SET_DRIVE_BUZZ,
  SET_DATA_ITEM_COST_BEARER,
} from "@/store/StudentEducation/types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [FINISHED_EDUCATION_STATE_SUCCESS](state: State, finishedEducationStateSuccess: boolean) {
    Object.assign(state, { finishedEducationStateSuccess });
  },
  [FINISHED_EDUCATION_STATE_ERROR](state: State, finishedEducationStateError: boolean) {
    Object.assign(state, { finishedEducationStateError });
  },
  [FINISHED_EDUCATION_STATE_IS_LOADING](state: State, finishedEducationStateIsLoading: boolean) {
    Object.assign(state, { finishedEducationStateIsLoading });
  },

  [AUTOMATIC_PAYMENT_STATE_SUCCESS](state: State, automaticPaymentStateSuccess: boolean) {
    Object.assign(state, { automaticPaymentStateSuccess });
  },
  [AUTOMATIC_PAYMENT_STATE_ERROR](state: State, automaticPaymentStateError: boolean) {
    Object.assign(state, { automaticPaymentStateError });
  },
  [AUTOMATIC_PAYMENT_STATE_IS_LOADING](state: State, automaticPaymentStateIsLoading: boolean) {
    Object.assign(state, { automaticPaymentStateIsLoading });
  },
  [SET_QUESTION_VERSION](state: State, questionVersion: any) {
    Object.assign(state, { questionVersion });
  },
  [SET_DRIVE_BUZZ](state: State, driveBuzzEnabled: any) {
    Object.assign(state, { driveBuzzEnabled });
  },
  [SET_DATA_ITEM_COST_BEARER](state: State, dataItemCostBearer: Record<string, any>) {
    Object.assign(state, { dataItemCostBearer });
  },
};
