import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_LIST,
  SET_DATA_ITEM_LOADING,
  SET_TOTAL,
  SET_DATA_ITEM,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import {
  SET_STUDENT_LIST_SUCCESS,
  SET_STUDENT_LIST_ERROR,
  SET_STUDENT_LIST_LOADING,
  SET_STUDENT_LIST,
  SET_OVERVIEW_INFO_ERROR,
  SET_OVERVIEW_INFO_SUCCESS,
  SET_OVERVIEW_INFO_LOADING,
  SET_OVERVIEW_INFO,
  SET_STUDENT_TOTAL,
} from "@/store/PaymentWorkflow/mutations";
import {
  SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_ERROR,
  SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_LOADING,
  SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_SUCCESS,
} from "@/store/PaymentWorkflow/types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_STUDENT_LIST_SUCCESS,
  SET_STUDENT_LIST_ERROR,
  SET_STUDENT_LIST_LOADING,
  SET_STUDENT_LIST,
  SET_OVERVIEW_INFO_ERROR,
  SET_OVERVIEW_INFO_SUCCESS,
  SET_OVERVIEW_INFO_LOADING,
  SET_OVERVIEW_INFO,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ITEM,
  SET_DATA_ID,
  SET_STUDENT_TOTAL,

  [SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_ERROR](state: State, livePayPaymentUpdateStatusError: Record<any, any> | null) {
    state.livePayPaymentUpdateStatusError = livePayPaymentUpdateStatusError;
  },
  [SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_SUCCESS](state: State, livePayPaymentUpdateStatusSuccess: boolean) {
    state.livePayPaymentUpdateStatusSuccess = livePayPaymentUpdateStatusSuccess;
  },
  [SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_LOADING](state: State, livePayPaymentUpdateStatusLoading: boolean) {
    state.livePayPaymentUpdateStatusLoading = livePayPaymentUpdateStatusLoading;
  },
};
