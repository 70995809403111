import { ActionTree } from "vuex";
import { State } from "./state";
import { create, update, findAll, findOne, filter, del } from "@/store/Crud/functions/actions";
import { archive } from "@/store/Crud/functions/archived/actions";
import {
  book,
  updateExamStatus,
  updateParticipantsList,
  uploadProtocol,
  resetSave,
  resetExamStatus,
  resetBooking,
  resetParticipantList,
  findAllPortions,
  ready,
  removeStudentFromExam,
  getExamTheoryPracticeInfo,
} from "@/store/Exam/actions";

export default <ActionTree<State, any>>{
  create,
  update,
  findAll,
  filter,
  findOne,
  archive,
  updateParticipantsList,
  updateExamStatus,
  book,
  uploadProtocol,
  resetSave,
  resetExamStatus,
  resetBooking,
  resetParticipantList,
  findAllPortions,
  ready,
  removeStudentFromExam,
  getExamTheoryPracticeInfo,
  del,
};
