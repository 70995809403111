import i18n from "@/i18n";
import { MODULE_CUSTOMERS, MODULE_CUSTOMERS_WRITE } from "@/constants/Permissions";
import AbibaroMiddleware from "@/middlewares/AbibaroMiddleware";

export default [
  {
    path: "/students",
    name: "Students",
    meta: {
      title: String(i18n.t("sidebar.students")),
      trans: "sidebar.students",
      permissions: [MODULE_CUSTOMERS],
    },
    component: () => import(/* webpackChunkName: "students" */ "@/views/Student/Students.vue"),
  },
  {
    path: "/students/create",
    name: "StudentCreate",
    meta: {
      title: String(i18n.t("students.new_student")),
      trans: "students.new_student",
      permissions: [MODULE_CUSTOMERS, MODULE_CUSTOMERS_WRITE],
    },
    component: () => import(/* webpackChunkName: "students-create" */ "@/views/Student/StudentCreate.vue"),
  },
  {
    path: "/students/edit/:id",
    name: "StudentEdit",
    meta: {
      title: String(i18n.t("sidebar.student")),
      trans: "sidebar.student",
      permissions: [MODULE_CUSTOMERS],
    },
    component: () => import(/* webpackChunkName: "students-edit" */ "@/views/Student/StudentEdit.vue"),
  },
  {
    path: "/students/abibaro/:id",
    name: "StudentAbibaroCreate",
    meta: {
      title: String(i18n.t("general.abibaro")),
      trans: "general.abibaro",
      permissions: [MODULE_CUSTOMERS, MODULE_CUSTOMERS_WRITE],
      middleware: [AbibaroMiddleware],
    },
    component: () => import(/* webpackChunkName: "students-abibaro-registration" */ "@/views/Student/AbibaroStudentRegistration.vue"),
  },
  {
    path: "/students/document-in-processing",
    name: "StudentUploadDocuments",
    meta: {
      title: String(i18n.t("general.file_upload")),
      trans: "general.file_upload",
      permissions: [MODULE_CUSTOMERS, MODULE_CUSTOMERS_WRITE],
    },
    props: true,
    component: () => import(/* webpackChunkName: "students-upload-documents" */ "@/views/Student/StudentUploadDocuments.vue"),
  },
  {
    path: "/education-events/:id",
    name: "EducationEventList",
    meta: {
      title: String(i18n.t("sidebar.student")),
      trans: "sidebar.student",
      permissions: [MODULE_CUSTOMERS],
    },
    component: () => import(/* webpackChunkName: "education-events" */ "@/views/Student/EducationEvent/EducationEventList.vue"),
    props: true,
  },
  {
    path: "/theory/upload-qrcode-document",
    name: "StudentUploadQrcodeDocument",
    meta: {
      title: String(i18n.t("general.upload")),
      trans: "general.upload",
      permissions: [MODULE_CUSTOMERS],
    },
    component: () => import(/* webpackChunkName: "students-upload-qrcode-document" */ "@/views/Student/StudentUploadQrcodeDocument.vue"),
  },
  {
    path: "/learn-status/:id",
    name: "LearnStatus",
    meta: {
      title: String(i18n.t("students.learn_status")),
      trans: "students.learn_status",
      permissions: [MODULE_CUSTOMERS],
    },
    component: () => import(/* webpackChunkName: "learn-status" */ "@/views/Student/LearnStatus/LearnStatus.vue"),
  },
  {
    path: "/mandate",
    name: "Mandate",
    meta: {
      title: String(i18n.t("general.new_mandate")),
      trans: "general.new_mandate",
      permissions: [MODULE_CUSTOMERS],
    },
    props: true,
    component: () => import(/* webpackChunkName: "mandate" */ "@/views/Student/Mandate.vue"),
  },
  {
    path: "/pre-exam/:id/:class",
    name: "PreExamsListQuestions",
    meta: {
      title: String(i18n.t("students.pre_exam")),
      trans: "students.pre_exam",
    },
    component: () => import(/* webpackChunkName: "pre-exam" */ "@/views/Student/LearnStatus/PreExamsList/PreExamsListQuestions.vue"),
  },
];
