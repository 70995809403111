import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_DATA_ITEM, SET_SUCCESS } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";

export const findOne = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`/accounts-config/current`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const update = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .put(`/accounts-config/current`, data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  findOne,
  update,
};
