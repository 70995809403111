import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll, del } from "@/store/Crud/functions/actions";

export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
  del,
};
