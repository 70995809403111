import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import { SET_MIME_TYPE, SET_DATA_ITEM_BLOB } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_MIME_TYPE](state: State, mimeType: string) {
    Object.assign(state, { mimeType });
  },

  [SET_DATA_ITEM_BLOB](state: State, dataItemBlob: Record<string, any>) {
    Object.assign(state, { dataItemBlob });
  },
};
