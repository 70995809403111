import { GetterTree } from "vuex";
import { State } from "./state";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";
import { getArchiveSuccess } from "@/store/Crud/functions/archived/getters";

export const getWorkHours = (state: State) => state.workHours;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getWorkHours,
  getId,
  getArchiveSuccess,
};
