import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import {
  SET_COMMUNICATION_BADGE,
  SET_COMMUNICATION_BADGE_LOADING,
  SET_COMMUNICATION_BADGE_SUCCESS,
  SET_EMAIL_BADGE,
  SET_REGISTRATION_BADGE,
} from "@/store/Communication/types";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";

export const notifications = async (store: any): Promise<void> => {
  return await axios
    .get("/registrations/count-unread")
    .then((response: AxiosResponse) => {
      store.commit(SET_REGISTRATION_BADGE, response.data);
      if (response.data) {
        store.commit(SET_COMMUNICATION_BADGE, true);
      }
    })
    .catch((error: AxiosError) => {
      console.error(error.message);
    });
};

export const notificationAsRead = async (store: ActionContext<any, any>, data: any): Promise<void> => {
  store.commit(SET_COMMUNICATION_BADGE_LOADING, true);
  store.commit(SET_COMMUNICATION_BADGE_SUCCESS, false);

  return await axios
    .post(`/registrations/change-read-state?registrationId=${data.registrationId}&read=${data.read}`)
    .then((response: AxiosResponse) => {
      if (response.status == 200) {
        const notificationCount = store.getters["getRegistrationBadge"];

        store.commit(SET_COMMUNICATION_BADGE_SUCCESS, true);

        // if (notificationCount > 0) {
        //   const newCount = notificationCount - 1;
        //   store.commit(SET_REGISTRATION_BADGE, newCount);
        //   store.commit(SET_COMMUNICATION_BADGE, Boolean(newCount));
        // }
      }
    })
    .catch((error: AxiosError) => {
      console.error(error.message);
    })
    .finally(() => {
      store.commit(SET_COMMUNICATION_BADGE_LOADING, false);
    });
};

export const findEmailBadge = async (store: any): Promise<void> => {
  return await axios
    .get(`emails/count-unread`)
    .then(({ data }) => {
      store.commit(SET_EMAIL_BADGE, data);
      return data;
    })
    .catch(({ response }) => {
      console.log(response);
    });
};

export default <ActionTree<State, any>>{
  notifications,
  findEmailBadge,
  notificationAsRead,
};
