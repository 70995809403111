import axios from "@/utils/axios";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
// @ts-ignore
import queryString from "query-string";

/**
 *Basic CRUD Service
 */

export default class CrudService {
  /**
   *
   * @param options
   */
  public async findOne(options: ICrudOptions): Promise<any> {
    return axios.get(options.resource + "/" + options.id);
  }

  /**
   *
   * @param options
   */
  public async filter(options: ICrudOptions, suffix = "/filter"): Promise<any> {
    return axios.post(`${options.resource}${suffix}`, options.filter);
  }

  /**
   *
   * @param options
   */
  public async findAll(options: ICrudOptions): Promise<any> {
    let url = options.resource;
    if (options.params) {
      const queryBuilder = queryString.stringify(options.params);
      url += "?" + queryBuilder;
    }
    return axios.get(url);
  }

  /**
   *
   * @param options
   */
  public async paginate(options: ICrudOptions): Promise<any> {
    return axios.get(options.resource + "/" + options.params.limit + "/" + options.params.offset);
  }

  /**
   *
   * @param options
   */
  public async create(options: ICrudOptions): Promise<any> {
    return axios.post(options.resource, options.data);
  }

  /**
   *
   * @param options
   */
  public async update(options: ICrudOptions): Promise<any> {
    if (!options || !options.id) {
      return axios.put(options.resource, options.data);
    }
    return axios.put(options.resource + "/" + options.id, options.data);
  }

  /**
   *
   * @param options
   */
  public async delete(options: ICrudOptions): Promise<any> {
    return axios.delete(options.resource + "/" + options.id);
  }

  /**
   *
   * @param options
   */
  public async uploadFile(options: ICrudOptions): Promise<any> {
    return axios.post(options.resource, options.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
