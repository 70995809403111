import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_DATA_LIST, SET_ERROR } from "./types";
import axios from "@/utils/axios";

const URL_SUCCESS = "/reports/practical-exam-success-rate";
const URL_THEORY_SUCCESS = "/reports/theory-exam-success-rate";
const URL_REPORT_SUCCESS = "/reports/successfully-passed-practical-exams";
const URL_REPORT_THEORY_SUCCESS = "/reports/successfully-passed-theory-exams";
const URL_REPORT_KM_PER_LESSON = "/reports/km-per-driving-lesson";
const URL_REPORT_DURATION_OF_TRAINING = "/reports/education-duration";

export const practicalExamSuccessRate = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL_SUCCESS}`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const theoryExamSuccessRate = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL_THEORY_SUCCESS}`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const practicalExamPassedRate = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL_REPORT_SUCCESS}`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const theoryExamPassedRate = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL_REPORT_THEORY_SUCCESS}`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const kmPerDrivingLesson = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL_REPORT_KM_PER_LESSON}`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const gaussianDurationOfTraining = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL_REPORT_DURATION_OF_TRAINING}`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
  practicalExamSuccessRate,
  practicalExamPassedRate,
  theoryExamSuccessRate,
  theoryExamPassedRate,
  kmPerDrivingLesson,
  gaussianDurationOfTraining,
};
