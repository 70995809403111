import axios from "@/utils/axios";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
// @ts-ignore
import queryString from "query-string";

/**
 * Archive Service
 */
export default class ArchiveService {
  /**
   *
   * @param options
   */
  public async archive(options: ICrudOptions): Promise<any> {
    const queryBuilder = queryString.stringify(options.params);
    return await axios.post(`${options.resource}/archive?${queryBuilder}`);
  }
}
