import i18n from "@/i18n";
import { MODULE_COMPANY } from "@/constants/Permissions";

export default [
  {
    path: "/configurations",
    name: "Configurations",
    meta: {
      title: String(i18n.t("sidebar.configurations")),
      trans: "sidebar.configurations",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "configurations" */ "@/views/Configuration/Configurations.vue"),
  },
];
