import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
} from "@/store/Crud/functions/mutations";
import {
  // dashboard
  SET_ERROR_DASHBOARD_USER_CALENDAR,
  SET_DATA_LIST_DASHBOARD_USER_CALENDAR,
  LOADING_DASHBOARD_USER_CALENDAR,

  // check instructor working hours
  SET_DATA_CHECK_WORKING_HOURS,
  LOADING_CHECK_WORKING_HOURS,
  SET_SUCCESS_CHECK_WORKING_HOURS,
  SET_ERROR_CHECK_WORKING_HOURS,

  // check working hours repeating event
  SET_DATA_CHECK_WORKING_HOURS_REPEATING_EVENT,
  LOADING_CHECK_WORKING_HOURS_REPEATING_EVENT,
  SET_SUCCESS_CHECK_WORKING_HOURS_REPEATING_EVENT,
  SET_ERROR_CHECK_WORKING_HOURS_REPEATING_EVENT,

  // check instructor overlapping appointments
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS,

  // check instructor overlapping appointments repeating event
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT,

  // check vehicle overlapping appointments
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE,

  // check vehicle overlapping appointments repeating event
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT,

  // check vehicle as array overlapping appointments
  SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY,
  LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY,
  SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY,
  SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY,
} from "@/store/Calendar/types";

export default <MutationTree<State>>{
  SET_DATA_ITEM_LOADING,
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,

  // check instructor working hours
  [LOADING_CHECK_WORKING_HOURS](state: State, payload: boolean) {
    state.instructorCheckWorkingHoursLoading = payload;
  },
  [SET_DATA_CHECK_WORKING_HOURS](state: State, payload: boolean | null) {
    state.instructorCheckWorkingHours = payload;
  },
  [SET_ERROR_CHECK_WORKING_HOURS](state: State, payload: any) {
    state.instructorCheckWorkingHoursError = payload;
  },
  [SET_SUCCESS_CHECK_WORKING_HOURS](state: State, payload: boolean) {
    state.instructorCheckWorkingHoursSuccess = payload;
  },

  // check instructor working hours repeating event
  [LOADING_CHECK_WORKING_HOURS_REPEATING_EVENT](state: State, payload: boolean) {
    state.instructorCheckWorkingHoursRepeatingEventLoading = payload;
  },
  [SET_DATA_CHECK_WORKING_HOURS_REPEATING_EVENT](state: State, payload: boolean | null) {
    state.instructorCheckWorkingHoursRepeatingEvent = payload;
  },
  [SET_ERROR_CHECK_WORKING_HOURS_REPEATING_EVENT](state: State, payload: any) {
    state.instructorCheckWorkingHoursRepeatingEventError = payload;
  },
  [SET_SUCCESS_CHECK_WORKING_HOURS_REPEATING_EVENT](state: State, payload: boolean) {
    state.instructorCheckWorkingHoursRepeatingEventSuccess = payload;
  },

  // check instructor overlapping appointments
  [LOADING_CHECK_OVERLAPPING_APPOINTMENTS](state: State, payload: boolean) {
    state.instructorCheckOverlappingAppointmentsLoading = payload;
  },
  [SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS](state: State, payload: boolean | null) {
    state.instructorCheckOverlappingAppointments = payload;
  },
  [SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS](state: State, payload: any) {
    state.instructorCheckOverlappingAppointmentsError = payload;
  },
  [SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS](state: State, payload: boolean) {
    state.instructorCheckOverlappingAppointmentsSuccess = payload;
  },

  // check instructor overlapping appointments repeating event
  [LOADING_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT](state: State, payload: boolean) {
    state.instructorCheckOverlappingAppointmentsRepeatingEventLoading = payload;
  },
  [SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT](state: State, payload: boolean | null) {
    state.instructorCheckOverlappingAppointmentsRepeatingEvent = payload;
  },
  [SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT](state: State, payload: any) {
    state.instructorCheckOverlappingAppointmentsRepeatingEventError = payload;
  },
  [SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_REPEATING_EVENT](state: State, payload: boolean) {
    state.instructorCheckOverlappingAppointmentsRepeatingEventSuccess = payload;
  },

  // check vehicle overlapping appointments
  [LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE](state: State, payload: boolean) {
    state.vehicleCheckOverlappingAppointmentsLoading = payload;
  },
  [SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE](state: State, payload: boolean | null) {
    state.vehicleCheckOverlappingAppointments = payload;
  },
  [SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE](state: State, payload: any) {
    state.vehicleCheckOverlappingAppointmentsError = payload;
  },
  [SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE](state: State, payload: boolean) {
    state.vehicleCheckOverlappingAppointmentsSuccess = payload;
  },

  //USER CALENDAR
  [LOADING_DASHBOARD_USER_CALENDAR](state: State, dashBoardUserLoadingCalendar: boolean) {
    Object.assign(state, { dashBoardUserLoadingCalendar });
  },
  [SET_DATA_LIST_DASHBOARD_USER_CALENDAR](state: State, dashBoardUserCalendar: Array<any>) {
    Object.assign(state, { dashBoardUserCalendar });
  },
  [SET_ERROR_DASHBOARD_USER_CALENDAR](state: State, dashBoardUserErrorCalendar: Record<any, any>) {
    Object.assign(state, { dashBoardUserErrorCalendar });
  },

  // check vehicle overlapping appointments repeating event
  [LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT](state: State, payload: boolean) {
    state.vehicleCheckOverlappingAppointmentsRepeatingEventLoading = payload;
  },
  [SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT](state: State, payload: boolean | null) {
    state.vehicleCheckOverlappingAppointmentsRepeatingEvent = payload;
  },
  [SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT](state: State, payload: any) {
    state.vehicleCheckOverlappingAppointmentsRepeatingEventError = payload;
  },
  [SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_REPEATING_EVENT](state: State, payload: boolean) {
    state.vehicleCheckOverlappingAppointmentsRepeatingEventSuccess = payload;
  },

  // check vehicle as array overlapping appointments
  [LOADING_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY](state: State, payload: boolean) {
    state.vehicleCheckOverlappingAppointmentsAsArrayLoading = payload;
  },
  [SET_DATA_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY](state: State, payload: boolean | null) {
    state.vehicleCheckOverlappingAppointmentsAsArray = payload;
  },
  [SET_ERROR_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY](state: State, payload: any) {
    state.vehicleCheckOverlappingAppointmentsAsArrayError = payload;
  },
  [SET_SUCCESS_CHECK_OVERLAPPING_APPOINTMENTS_VEHICLE_AS_ARRAY](state: State, payload: boolean) {
    state.vehicleCheckOverlappingAppointmentsAsArraySuccess = payload;
  },
};
