import { State } from "./state";
import { GetterTree } from "vuex";
import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getPngMimeType = (state: State) => state.pngMimeType;
export const getPngSuccess = (state: State) => state.pngSuccess;
export const getPngItem = (state: State) => state.pngItem;
export const getPngFileName = (state: State) => state.pngFileName;
export const getPdfMimeType = (state: State) => state.pdfMimeType;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getPngMimeType,
  getPngSuccess,
  getPngItem,
  getPngFileName,
  getPdfMimeType,
};
