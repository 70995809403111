import { State } from "./state";
import { GetterTree } from "vuex";
import { getDataList, getError, getSuccess, getIsLoading, getId, getTotal, getDataItem } from "@/store/Crud/functions/getters";

export default <GetterTree<State, any>>{
  getDataList,
  getError,
  getSuccess,
  getIsLoading,
  getId,
  getTotal,
  getDataItem,
};
