import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { ActionTree } from "vuex";
import { State } from "./state";

export default <ActionTree<State, any>>{
  findAll,
  findOne,
  update,
  create,
  filter,
};
