import { ActionTree } from "vuex";
import { State } from "./state";
// @ts-ignore
import queryString from "query-string";
import { create, filter, findAll, findOne, update } from "@/store/Crud/functions/actions";

import { archive } from "@/store/Crud/functions/archived/actions";
import axios from "@/utils/axios";
import {
  SET_BASIC_FEE_PRODUCTS,
  SET_BASIC_FEE_PRODUCTS_ERROR,
  SET_BASIC_FEE_PRODUCTS_LOADING,
  SET_BASIC_FEE_PRODUCTS_SUCCESS,
  SET_BOOK_AT_STUDENT_REGISTRATION_ALLOWED,
  SET_LEARNING_MATERIAL_PRODUCTS,
  SET_LEARNING_MATERIAL_PRODUCTS_ERROR,
  SET_LEARNING_MATERIAL_PRODUCTS_LOADING,
  SET_LEARNING_MATERIAL_PRODUCTS_SUCCESS,
} from "@/store/Product/types";
import { AxiosError, AxiosResponse } from "axios";

const URL = "/products";

export const getByStudentIdAndLicenseClass = async (store: any, data: any): Promise<void> => {
  const queryBuilder = queryString.stringify(data);

  store.commit("IS_LOADING", true);
  return await axios
    .get(`${URL}/driving-lesson-products?${queryBuilder}`)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_DATA_LIST", data);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
    });
};

export const bookAddons = async (store: any, data: any): Promise<void> => {
  store.commit("IS_LOADING", true);
  store.commit("SET_SUCCESS", null);
  return await axios
    .post(`${URL}/book-addons?studentId=${data.id}`, data.data)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_SUCCESS", true);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
      store.commit("SET_SUCCESS", false);
    });
};

export const checkBookAtStudentRegistrationAllowed = async (store: any, productId: number): Promise<void> => {
  store.commit("IS_LOADING", true);
  store.commit("SET_SUCCESS", null);
  return await axios
    .get(`${URL}/${productId}/is-book-at-student-registration-allowed`)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_SUCCESS", true);
      store.commit(SET_BOOK_AT_STUDENT_REGISTRATION_ALLOWED, data);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
      store.commit("SET_SUCCESS", false);
    });
};

export const learningMaterialProducts = async (store: any, options: { studentId: number; licenseClass: string }): Promise<void> => {
  store.commit(SET_LEARNING_MATERIAL_PRODUCTS_LOADING, true);
  store.commit(SET_LEARNING_MATERIAL_PRODUCTS_SUCCESS, false);
  store.commit(SET_LEARNING_MATERIAL_PRODUCTS_ERROR, null);
  return await axios
    .get(`${URL}/learning-material-products?studentId=${options.studentId}&licenseClass=${options.licenseClass}`)
    .then((response: AxiosResponse) => {
      store.commit(SET_LEARNING_MATERIAL_PRODUCTS, response.data);
      store.commit(SET_LEARNING_MATERIAL_PRODUCTS_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_LEARNING_MATERIAL_PRODUCTS_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(SET_LEARNING_MATERIAL_PRODUCTS_LOADING, false);
    });
};

export const basicFeeProducts = async (store: any, options: { studentId: number; licenseClass: string }): Promise<void> => {
  store.commit(SET_BASIC_FEE_PRODUCTS_LOADING, true);
  store.commit(SET_BASIC_FEE_PRODUCTS_SUCCESS, false);
  store.commit(SET_BASIC_FEE_PRODUCTS_ERROR, null);
  return await axios
    .get(`${URL}/basic-fee-products?studentId=${options.studentId}&licenseClass=${options.licenseClass}`)
    .then((response: AxiosResponse) => {
      store.commit(SET_BASIC_FEE_PRODUCTS, response.data);
      store.commit(SET_BASIC_FEE_PRODUCTS_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_BASIC_FEE_PRODUCTS_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(SET_BASIC_FEE_PRODUCTS_LOADING, false);
    });
};

export const bookTheoryExamProduct = async (store: any, options: any): Promise<void> => {
  store.commit("SET_SUCCESS", null);
  store.commit("SET_ERROR", null);
  return await axios
    .post(`student-booked-product/on-failed-exam/${options.studentEducationId}/${options.studentTheoryExamId}`)
    .then(({ data }) => {
      store.commit("SET_SUCCESS", true);
    })
    .catch(({ response }) => {
      store.commit("SET_ERROR", response.data);
      store.commit("SET_SUCCESS", false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  findAll,
  update,
  archive,
  getByStudentIdAndLicenseClass,
  bookAddons,
  checkBookAtStudentRegistrationAllowed,
  learningMaterialProducts,
  basicFeeProducts,
  bookTheoryExamProduct,
};
