import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMagnifyingGlass,
  faCartShopping,
  faFileArrowUp,
  faXmark,
  faBars,
  faCheck,
  faArrowUpFromBracket,
  faEllipsisVertical,
  faGauge,
  faUser,
  faKey,
  faSignInAlt,
  faLightbulbOn,
  faPause,
  faPlay,
  faSplit,
  faSendBackward,
  faFilter,
  faEnvelopeDot,
  faHouse,
  faListCheck,
  faAddressCard,
  faBuilding,
  faCalendarDays,
  faScreenUsers,
  faGraduationCap,
  faChartPie,
  faWallet,
  faCalculatorSimple,
  faCarBuilding,
  faUserNurse,
  faClock,
  faArrowDown,
  faCoins,
  faPlus,
  faX,
  faCircle,
  faFlag,
  faGear,
  faPaperPlane,
  faExclamation,
  faStopwatch,
  faCar,
  faBullseyeArrow,
  faFaceSunglasses,
  faArrowUp,
  faFile,
  faWavePulse,
  faBoxArchive,
  faLink,
  faBan,
  faExclamationCircle,
  faFilePlus,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faMagnifyingGlass,
  faCartShopping,
  faFileArrowUp,
  faXmark,
  faBars,
  faCheck,
  faArrowUpFromBracket,
  faGauge,
  faEllipsisVertical,
  faUser,
  faKey,
  faSignInAlt,
  faLightbulbOn,
  faPause,
  faPlay,
  faSplit,
  faSendBackward,
  faFilter,
  faEnvelopeDot,
  faHouse,
  faListCheck,
  faAddressCard,
  faBuilding,
  faCalendarDays,
  faScreenUsers,
  faGraduationCap,
  faChartPie,
  faWallet,
  faCalculatorSimple,
  faCarBuilding,
  faUserNurse,
  faClock,
  faArrowDown,
  faCoins,
  faPlus,
  faX,
  faCircle,
  faFlag,
  faGear,
  faPaperPlane,
  faExclamation,
  faStopwatch,
  faCar,
  faBullseyeArrow,
  faFaceSunglasses,
  faArrowUp,
  faFile,
  faWavePulse,
  faBoxArchive,
  faLink,
  faBan,
  faExclamationCircle,
  faFilePlus
);
