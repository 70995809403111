import i18n from "@/i18n";

export default [
  {
    path: "/onboard/driving-school/success",
    name: "GocardlessSuccessful",
    meta: {
      title: String(i18n.t("general.gocardless_success")),
      trans: "general.gocardless_success",
    },
    component: () => import(/* webpackChunkName: "gocardless_successful" */ "@/views/Gocardless/GocardlessSuccessful.vue"),
  },
];
