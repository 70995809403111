import i18n from "@/i18n";
import { MODULE_COMPANY } from "@/constants/Permissions";

export default [
  {
    path: "/price-lists",
    name: "PriceLists",
    meta: {
      title: String(i18n.t("general.price_lists")),
      trans: "general.price_lists",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "price-list" */ "@/views/PriceList/PriceLists.vue"),
  },
];
