import localForage from "localforage";
// @ts-ignore
import memoryDriver from "localforage-memoryStorageDriver";

export const STORAGE_KEY = "http_image_cache";

export const remove = async (key: string) => {
  await localForage.defineDriver(memoryDriver);

  const newKey = process.env.VUE_APP_BACKEND_BASE_URL + "/" + key;

  const forageStore = localForage.createInstance({
    driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE, memoryDriver._driver],
    name: STORAGE_KEY,
  });

  await forageStore.removeItem(newKey);
};
