import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_SUCCESS } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

export const deleteOtherAppointment = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .delete(`${options.resource}/${options.id}`)
    .then(() => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  deleteOtherAppointment,
};
