import { State } from "./state";
import { MutationTree } from "vuex";

import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import {
  SET_SELECTED_EMAIL_CONTENT,
  SET_TEMPLATE_CATEGORIES,
  SET_PLACEHOLDERS,
  SET_TEMPLATES_BY_CATEGORY,
  SET_TEMPLATES,
  SET_SELECTED_TEMPLATE,
} from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,

  [SET_SELECTED_EMAIL_CONTENT](state: State, payload) {
    state.selectedEmailContent = payload;
  },
  [SET_TEMPLATES_BY_CATEGORY](state: State, payload) {
    state.templatesByCategory = payload;
  },
  [SET_TEMPLATES](state: State, payload) {
    state.templates = payload;
  },
  [SET_SELECTED_TEMPLATE](state: State, payload) {
    state.selectedTemplate = payload;
  },
  [SET_TEMPLATE_CATEGORIES](state: State, payload) {
    state.templateCategories = payload;
  },
  [SET_PLACEHOLDERS](state: State, payload) {
    state.placeholders = payload;
  },
};
