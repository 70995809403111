




import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const CommunicationModule = namespace("communication");

@Component
export default class EmailBadge extends Vue {
  public name = "EmailBadge";

  @CommunicationModule.Action("findEmailBadge")
  public findEmailBadge!: any;

  @CommunicationModule.Getter("getEmailBadge")
  public emailBadge!: number;

  public async mounted(): Promise<any> {
    await this.findEmailBadge();
  }
}
