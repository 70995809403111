import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
// @ts-ignore
import queryString from "query-string";
import CrudActions from "@/store/Crud/classes/CrudActions";

const crudActions = new CrudActions();

export const updateParticipantsList = async (store: any, data: any): Promise<void> => {
  store.commit("SET_PARTICIPANT_LIST_SUCCESS", false);
  store.commit("IS_PARTICIPANT_LIST_LOADING", true);
  return await axios
    .put(data.resource + "/participants", data.data)
    .then(({ data }) => {
      store.commit("IS_PARTICIPANT_LIST_LOADING", false);
      store.commit("SET_PARTICIPANT_LIST_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_PARTICIPANT_LIST_LOADING", false);
      store.commit("SET_ERROR", error);
      store.commit("SET_PARTICIPANT_LIST_SUCCESS", false);
      //crudActions.dispatchError(store, error);
    });
};

export const updateExamStatus = async (store: any, data: any): Promise<void> => {
  store.commit("SET_EXAM_STATUS_SUCCESS", false);
  store.commit("IS_EXAM_STATUS_LOADING", true);
  const endpoint = data.endpoint ? data.endpoint : "update-student-exam-statuses";
  return await axios
    .put(data.resource + "/" + endpoint, data.data)
    .then(({ data }) => {
      store.commit("IS_EXAM_STATUS_LOADING", false);
      store.commit("SET_EXAM_STATUS_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_EXAM_STATUS_LOADING", false);
      store.commit("SET_EXAM_STATUS_SUCCESS", false);
      store.commit("SET_ERROR", error);
      //crudActions.dispatchError(store, error);
    });
};

export const book = async (store: any, data: any): Promise<void> => {
  store.commit("IS_BOOKING_LOADING", true);
  store.commit("SET_BOOKING_SUCCESS", false);
  return await axios
    .post(`${data.resource}/${data.id}/book`)
    .then(({ data }) => {
      store.commit("IS_BOOKING_LOADING", false);
      store.commit("SET_BOOKING_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_BOOKING_LOADING", false);
      store.commit("SET_ERROR", error);
      store.commit("SET_BOOKING_SUCCESS", false);
      //crudActions.dispatchError(store, error);
    });
};

export const uploadProtocol = async (store: any, data: any): Promise<void> => {
  store.commit("IS_UPLOAD_LOADING", true);
  store.commit("SET_UPLOAD_SUCCESS", false);
  return await axios
    .post(`${data.resource}/upload-protocol`, data.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit("IS_UPLOAD_LOADING", false);
      store.commit("SET_UPLOAD_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_UPLOAD_LOADING", false);
      store.commit("SET_UPLOAD_SUCCESS", false);
      store.commit("SET_ERROR", error);
      //crudActions.dispatchError(store, error);
    });
};

export const findAllPortions = async (store: any, data: ICrudOptions): Promise<void> => {
  store.commit("SET_PORTION_SUCCESS", null);
  store.commit("SET_PORTION_LOADING", true);

  const queryBuilder = queryString.stringify(data.params);

  return axios
    .get(`${data.resource}?${queryBuilder}`)
    .then((response: AxiosResponse) => {
      store.commit("SET_PORTION_DATA_LIST", response.data);
      store.commit("SET_PORTION_SUCCESS", true);
      store.commit("SET_PORTION_LOADING", false);
    })
    .catch((error: AxiosError) => {
      store.commit("SET_PORTION_SUCCESS", false);
      store.commit("SET_PORTION_ERROR", false);
      store.commit("SET_PORTION_LOADING", false);
    });
};

export const resetSave = (store: any, options: ICrudOptions): void => {
  store.commit("SET_SUCCESS", false);
  store.commit("SET_DATA_ITEM", null);
  store.commit("SET_DATA_ID", null);
  store.commit("SET_ERROR", null);
  store.commit("IS_LOADING", false);

  store.commit("SET_PARTICIPANT_LIST_SUCCESS", false);
  store.commit("IS_PARTICIPANT_LIST_LOADING", false);
  store.commit("SET_EXAM_STATUS_SUCCESS", false);
  store.commit("IS_EXAM_STATUS_LOADING", false);
  store.commit("SET_BOOKING_SUCCESS", false);
  store.commit("IS_BOOKING_LOADING", false);
  store.commit("SET_UPLOAD_SUCCESS", false);
  store.commit("IS_UPLOAD_LOADING", false);
};

export const resetCreate = (store: any, options: ICrudOptions): void => {
  store.commit("SET_SUCCESS", false);
  store.commit("SET_DATA_ID", null);
  store.commit("SET_ERROR", null);
  store.commit("IS_LOADING", false);
};

export const resetUpdate = (store: any, options: ICrudOptions): void => {
  store.commit("SET_SUCCESS", false);
  store.commit("SET_DATA_ID", null);
  store.commit("SET_DATA_ITEM", null);
  store.commit("SET_ERROR", null);
  store.commit("IS_LOADING", false);
};

export const resetParticipantList = (store: any, options: ICrudOptions): void => {
  store.commit("SET_PARTICIPANT_LIST_SUCCESS", false);
  store.commit("IS_PARTICIPANT_LIST_LOADING", false);
  store.commit("SET_ERROR", null);
};

export const resetExamStatus = (store: any, options: ICrudOptions): void => {
  store.commit("SET_EXAM_STATUS_SUCCESS", false);
  store.commit("IS_EXAM_STATUS_LOADING", false);
  store.commit("SET_ERROR", null);
};
export const resetBooking = (store: any, options: ICrudOptions): void => {
  store.commit("SET_BOOKING_SUCCESS", false);
  store.commit("IS_BOOKING_LOADING", false);
  store.commit("SET_ERROR", null);
};

export const resetUpload = (store: any, options: ICrudOptions): void => {
  store.commit("SET_UPLOAD_SUCCESS", false);
  store.commit("IS_UPLOAD_LOADING", false);
  store.commit("SET_ERROR", null);
};

export const ready = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit("SET_READY_SUCCESS", null);
  store.commit("SET_READY_LOADING", true);

  const queryBuilder = queryString.stringify(options.params);

  return axios
    .post(`${options.resource}/${options.id}/mark-as-ready`)
    .then((response: AxiosResponse) => {
      store.commit("SET_READY_SUCCESS", true);
      store.commit("SET_READY_LOADING", false);
    })
    .catch((error: AxiosError) => {
      store.commit("SET_READY_LOADING", false);
      store.commit("SET_READY_SUCCESS", false);
      store.commit("SET_READY_ERROR", false);
    });
};

export const removeStudentFromExam = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit("IS_LOADING", true);
  store.commit("SET_REMOVE_PARTICIPANT_SUCCESS", false);
  return await axios
    .delete(`${options.resource}/student-exam/${options.id}`)
    .then(({ data }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_REMOVE_PARTICIPANT_SUCCESS", true);
    })
    .catch(({ response }) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", response.data);
      store.commit("SET_REMOVE_PARTICIPANT_SUCCESS", false);
    });
};

export const getExamTheoryPracticeInfo = async ({ commit }: any, options: any) => {
  const { resourceUrl } = options;
  commit("IS_LOADING", true);

  return await axios
    .get(resourceUrl)
    .then(({ data }) => {
      commit("IS_LOADING", false);
      commit("SET_EDUCATION_STATUS_INFO", data);
    })
    .catch(() => {
      commit("IS_LOADING", false);
    });
};
