import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll, del } from "@/store/Crud/functions/actions";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR } from "./types";

const URL = "warnings";
export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  del,
};
