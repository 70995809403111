import { ActionContext, ActionTree } from "vuex";
import { PaymentWorkflowState } from "./state";
import { findAll } from "@/store/Crud/functions/actions";
import {
  SET_STUDENT_LIST_SUCCESS,
  SET_STUDENT_LIST_ERROR,
  SET_STUDENT_LIST_LOADING,
  SET_STUDENT_LIST,
  SET_OVERVIEW_INFO_ERROR,
  SET_OVERVIEW_INFO_SUCCESS,
  SET_OVERVIEW_INFO_LOADING,
  SET_OVERVIEW_INFO,
  SET_CANCEL_INVOICE_LOADING,
  SET_CANCEL_INVOICE_SUCCESS,
  SET_CANCEL_INVOICE_ERROR,
  SET_CANCEL_INVOICE,
  SET_COST_BEARER_STUDENT_LIST_ERROR,
  SET_COST_BEARER_STUDENT_LIST_SUCCESS,
  SET_COST_BEARER_STUDENT_LIST_LOADING,
  SET_COST_BEARER_STUDENT_LIST,
  SET_STUDENT_TOTAL,
} from "./types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import i18n from "@/i18n";
import { IPaymentWorkflowStudent } from "@/interfaces/IPaymentWorkflowStudent";
import { ICostBearerStudentListFilter } from "@/interfaces/ICostBearerStudentListFilter";

const messageDispatcher = new MessageDispatcher();

const URL = "/payment-workflows";

export const findAllStudentList = async (store: ActionContext<any, any>, filter: Record<any, any>): Promise<void> => {
  store.commit(SET_STUDENT_LIST_LOADING, true);
  store.commit(SET_STUDENT_LIST_SUCCESS, false);
  store.commit(SET_STUDENT_LIST_ERROR, null);
  return await axios
    .post(`${URL}/paged-students-list/filter`, filter)
    .then((response: AxiosResponse) => {
      store.commit(SET_STUDENT_LIST_LOADING, false);
      store.commit(SET_STUDENT_LIST, response.data.data);
      store.commit(SET_STUDENT_TOTAL, response.data.total);
      store.commit(SET_STUDENT_LIST_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_STUDENT_LIST_LOADING, false);
      store.commit(SET_STUDENT_LIST_ERROR, error);
    });
};

export const findOverviewInfo = async (store: ActionContext<any, any>, paymentWorkflowId: number): Promise<void> => {
  store.commit(SET_OVERVIEW_INFO_LOADING, true);
  store.commit(SET_OVERVIEW_INFO_SUCCESS, false);
  store.commit(SET_OVERVIEW_INFO_ERROR, null);
  return await axios
    .get(`${URL}/overview-info?paymentWorkflowId=${paymentWorkflowId}`)
    .then((response: AxiosResponse) => {
      store.commit(SET_OVERVIEW_INFO_LOADING, false);
      store.commit(SET_OVERVIEW_INFO, response.data);
      store.commit(SET_OVERVIEW_INFO_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_OVERVIEW_INFO_LOADING, false);
      store.commit(SET_OVERVIEW_INFO_ERROR, error);
    });
};

export const cancelInvoice = async (store: ActionContext<any, any>, invoiceForCancellationId: number): Promise<void> => {
  store.commit(SET_CANCEL_INVOICE_LOADING, true);
  store.commit(SET_CANCEL_INVOICE_SUCCESS, false);
  store.commit(SET_CANCEL_INVOICE_ERROR, null);
  return await axios
    .post(`/payment-documents/create-cancellation-invoice?invoiceForCancellationId=${invoiceForCancellationId}`)
    .then((response: AxiosResponse) => {
      store.commit(SET_CANCEL_INVOICE_SUCCESS, true);
      store.commit(SET_CANCEL_INVOICE, response.data);
      messageDispatcher.dispatchSuccessMessage(store, String(i18n.t("messages.save_success", { item: null })));
    })
    .catch((error: AxiosError) => {
      store.commit(SET_CANCEL_INVOICE_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(SET_CANCEL_INVOICE_LOADING, false);
    });
};

export const findAllCostBearerStudentList = async (
  store: ActionContext<any, any>,
  costBearerStudentListFilter: ICostBearerStudentListFilter
): Promise<void> => {
  store.commit(SET_COST_BEARER_STUDENT_LIST_LOADING, true);
  store.commit(SET_COST_BEARER_STUDENT_LIST_SUCCESS, false);
  store.commit(SET_COST_BEARER_STUDENT_LIST_ERROR, null);
  return await axios
    .post<IPaymentWorkflowStudent>(`${URL}/cost-bearer/${costBearerStudentListFilter.costBearerId}/students-list/filter`, costBearerStudentListFilter)
    .then((response: AxiosResponse) => {
      store.commit(SET_COST_BEARER_STUDENT_LIST_SUCCESS, true);
      store.commit(SET_COST_BEARER_STUDENT_LIST, response.data);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_COST_BEARER_STUDENT_LIST_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(SET_COST_BEARER_STUDENT_LIST_LOADING, false);
    });
};

export default <ActionTree<PaymentWorkflowState, any>>{
  findAll,
  findAllStudentList,
  findOverviewInfo,
  cancelInvoice,
  findAllCostBearerStudentList,
};
