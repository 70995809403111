import { MutationTree } from "vuex";
import { State } from "./state";
import { IMainTab } from "@/interfaces/IMainTab";
import { addObject, removeObject } from "@/utils/ArrayUtils";
import _ from "lodash";

export function SET_ACTIVE(state: State, active: IMainTab) {
  state.active = active;
}

export function ADD_TAB(state: State, tab: IMainTab) {
  addObject(state.tabs, tab);
  sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
}

export function REMOVE_TAB(state: State, tab: IMainTab) {
  removeObject(state.tabs, tab);
  sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
}

export function REMOVE_TAB_BY_ID(state: State, id: string) {
  const tab = state.tabs.find((t: any) => t.id == id);
  if (tab) {
    removeObject(state.tabs, tab);
    sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
  }
}

export function SET_CUSTOM_LABEL(state: State, customLabel: string | null) {
  state.customLabel = customLabel;
  sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
}

export function COPY_TABS(state: State, tabs: Array<IMainTab>) {
  state.tabs = [...tabs];
  sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
}

export function PUSH_TAB_HISTORY(state: State, tab: IMainTab) {
  _.remove(state.tabsHistory, function (historyTab: IMainTab) {
    return historyTab.id == tab.id;
  });
  state.tabsHistory.push(tab);
  sessionStorage.setItem("tabs-history", JSON.stringify(state.tabsHistory));
}

export function PUSH_TABS_HISTORY(state: State, tabs: Array<IMainTab>) {
  state.tabsHistory = tabs;
}

export function POP_TAB_HISTORY(state: State) {
  state.tabsHistory.pop();
  sessionStorage.setItem("tabs-history", JSON.stringify(state.tabsHistory));
}

export function REMOVE_TAB_HISTORY_BY_ID(state: State, tab: IMainTab) {
  _.remove(state.tabsHistory, function (tabHistory) {
    return tabHistory.id == tab.id;
  });
  sessionStorage.setItem("tabs-history", JSON.stringify(state.tabsHistory));
}

export default <MutationTree<State>>{
  SET_ACTIVE,
  ADD_TAB,
  REMOVE_TAB,
  REMOVE_TAB_BY_ID,
  SET_CUSTOM_LABEL,
  COPY_TABS,
  PUSH_TAB_HISTORY,
  POP_TAB_HISTORY,
  PUSH_TABS_HISTORY,
  REMOVE_TAB_HISTORY_BY_ID,
};
