import i18n from "@/i18n";
import { MODULE_REPORTS } from "@/constants/Permissions";

export default [
  {
    path: "/training",
    name: "Training",
    meta: {
      title: String(i18n.t("sidebar.training")),
      trans: "sidebar.training",
      permissions: [MODULE_REPORTS],
    },
    component: () => import(/* webpackChunkName: "training" */ "@/views/Report/Training.vue"),
  },
  {
    path: "/finances",
    name: "Finance",
    meta: {
      title: String(i18n.t("sidebar.finances")),
      trans: "sidebar.finances",
      permissions: [MODULE_REPORTS],
    },
    component: () => import(/* webpackChunkName: "finance" */ "@/views/Report/Finance.vue"),
  },
  {
    path: "/learn",
    name: "Learn",
    meta: {
      title: String(i18n.t("sidebar.learn")),
      trans: "sidebar.learn",
      permissions: [MODULE_REPORTS],
    },
    component: () => import(/* webpackChunkName: "learn" */ "@/views/Report/Learn.vue"),
  },
  {
    path: "/planning",
    name: "Planning",
    meta: {
      title: String(i18n.t("sidebar.planning")),
      trans: "sidebar.planning",
      permissions: [MODULE_REPORTS],
    },
    component: () => import(/* webpackChunkName: "planning" */ "@/views/Report/Planning.vue"),
  },
  {
    path: "/export",
    name: "Export",
    meta: {
      title: String(i18n.t("sidebar.export")),
      trans: "sidebar.export",
      permissions: [MODULE_REPORTS],
    },
    component: () => import(/* webpackChunkName: "export" */ "@/views/Report/Export.vue"),
  },
  {
    path: "/invoice",
    name: "Invoice",
    meta: {
      title: String(i18n.t("general.cost_invoice")),
      trans: "general.cost_invoice",
      permissions: [MODULE_REPORTS],
    },
    component: () => import(/* webpackChunkName: "invoice" */ "@/views/Report/Invoice/Invoice.vue"),
  },
];
