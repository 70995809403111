import axios, { AxiosError, AxiosRequestConfig } from "axios";
import SessionStorageService from "@/services/SessionStorageService";
import UserService from "@/services/UserService";
import Vue from "vue";
import AxiosCache from "@/utils/AxiosCache";

export const $axios = axios.create();

let cancelTokenSource: any;

const instance = axios.create();

export const cacheableAxios = async () => {
  const _axiosInstance = AxiosCache.Instance;

  const _axios = await _axiosInstance._axios;

  _axios.interceptors.request.use((config: any) => {
    const token: string | null = SessionStorageService.getFromLocalStorage("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token.replaceAll('"', "");
    }
    return config;
  });

  return _axios;
};

instance.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;
$axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token: string | null = SessionStorageService.getFromLocalStorage("token");

  if (typeof cancelTokenSource !== typeof undefined) {
    cancelTokenSource.cancel("canceled");
  }

  cancelTokenSource = axios.CancelToken.source();
  //config.cancelToken = cancelTokenSource.token;

  if (token) {
    config.headers["Authorization"] = "Bearer " + token.replaceAll('"', "");
  }
  return config;
});

$axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token: string | null = SessionStorageService.getFromLocalStorage("token");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token.replaceAll('"', "");
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error instanceof axios.Cancel) {
      return Promise.reject(error);
    }

    // Handle Token Timeouts
    if (error.response?.status === 401) {
      UserService.clearUser();
      window.location.reload();

      // Handle Unprocessable Entity
    } else if (error.response?.status === 422) {
      const { globalErrors, fieldErrors } = error.response.data;

      if (fieldErrors.length) {
        const fieldError = `${fieldErrors[0].fieldName} ${fieldErrors[0].error}`;
        Vue.toasted.error(fieldError);
      }
      Vue.toasted.error(globalErrors[0]);

      // Handle any error
    } else {
      // if (!(error.response?.data instanceof ArrayBuffer)) {
      Vue.toasted.error(error.response?.data.message || error.message);
      //}
    }

    return Promise.reject(error);
  }
);

export default instance;
