import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_DATA_LIST, SET_SUCCESS } from "./types";
import axios from "@/utils/axios";

const URL = "/students-filter";
const URL_STUDENTS = "/students";

export const findFilterByInstructorId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL}/filter?instructorId=${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const list = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL_STUDENTS}/student-list`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findFilterByInstructorId,
  list,
};
