import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  SET_DATA_DELETED,
} from "@/store/Crud/functions/mutations";
import { SET_ADDON_DATA_LIST } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_DELETED,
  SET_DATA_ITEM_LOADING,

  [SET_ADDON_DATA_LIST](state: State, addonDataList: Array<any>) {
    Object.assign(state, { addonDataList });
  },
};
