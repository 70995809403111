import { State } from "./state";
import { GetterTree } from "vuex";
import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getPdfMimeType = (state: State) => state.pdfMimeType;
export const getPngMimeType = (state: State) => state.pngMimeType;
export const getLogoMimeType = (state: State) => state.logoMimeType;
export const getPdfSuccess = (state: State) => state.pdfSuccess;
export const getPngSuccess = (state: State) => state.pngSuccess;
export const getLogoSuccess = (state: State) => state.logoSuccess;
export const getPdfItem = (state: State) => state.pdfItem;
export const getPngItem = (state: State) => state.pngItem;
export const getLogoItem = (state: State) => state.logoItem;
export const getPdfFileName = (state: State) => state.pdfFileName;
export const getPngFileName = (state: State) => state.pngFileName;
export const getLogoFileName = (state: State) => state.logoFileName;
export const getDrivingSchoolSwitches = (state: State) => state.drivingSchoolSwitches;
export const getSignatureStatuses = (state: State) => state.signatureStatuses;
export const getQRCodeUrl = (state: State) => state.qrCodeUrl;
export const getDriveBuzzOnboardingLinks = (state: State) => state.driveBuzzOnboardingLinks;
export const getQRCodeUrlCourse = (state: State) => state.qrCodeUrlCourse;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getPdfMimeType,
  getPngMimeType,
  getLogoMimeType,
  getPdfSuccess,
  getPngSuccess,
  getLogoSuccess,
  getPdfItem,
  getPngItem,
  getLogoItem,
  getPngFileName,
  getPdfFileName,
  getLogoFileName,
  getDrivingSchoolSwitches,
  getSignatureStatuses,
  getQRCodeUrl,
  getDriveBuzzOnboardingLinks,
  getQRCodeUrlCourse,
};
