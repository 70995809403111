import { State } from "./state";
import { MutationTree } from "vuex";
import {
  SET_SELECTED_AUTOMATIC_PAYMENT_STUDENT,
  SET_SELECTED_CLASSIC_PAY_STUDENTS,
  SET_SELECTED_COST_BEARER_CLASSIC_PAY_STUDENTS,
  SET_SELECTED_FLASH_PAY_STUDENTS,
  SET_SELECTED_COST_BEARER_SINGLE_STUDENT,
} from "./types";

export default <MutationTree<State>>{
  [SET_SELECTED_CLASSIC_PAY_STUDENTS](state: State, selectedClassicPayStudents: Array<any>) {
    Object.assign(state, { selectedClassicPayStudents });
  },
  [SET_SELECTED_AUTOMATIC_PAYMENT_STUDENT](state: State, selectedAutomaticPaymentStudent: Record<any, any>) {
    Object.assign(state, { selectedAutomaticPaymentStudent });
  },
  [SET_SELECTED_FLASH_PAY_STUDENTS](state: State, selectedFlashPayStudents: Array<any>) {
    Object.assign(state, { selectedFlashPayStudents });
  },
  [SET_SELECTED_COST_BEARER_CLASSIC_PAY_STUDENTS](state: State, selectedCostBearerClassicPayStudents: Array<any>) {
    Object.assign(state, { selectedCostBearerClassicPayStudents });
  },
  [SET_SELECTED_COST_BEARER_SINGLE_STUDENT](state: State, selectedCostBearerSingleStudent: Record<any, any>) {
    Object.assign(state, { selectedCostBearerSingleStudent });
  },
};
