import { State } from "./state";
import { GetterTree } from "vuex";
import { getDataList } from "@/store/Crud/functions/getters";
import {
  getStudentList,
  getStudentListError,
  getStudentListSuccess,
  getStudentListLoading,
  getOverviewInfo,
  getOverviewInfoError,
  getOverviewInfoSuccess,
  getOverviewInfoLoading,
  getStudentListTotal,
} from "@/store/PaymentWorkflow/getters";

export default <GetterTree<State, any>>{
  getDataList,
  getStudentList,
  getStudentListError,
  getStudentListSuccess,
  getStudentListLoading,
  getOverviewInfo,
  getOverviewInfoError,
  getOverviewInfoSuccess,
  getOverviewInfoLoading,
  getStudentListTotal,
};
