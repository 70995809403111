import i18n from "@/i18n";

export default [
  {
    path: "/",
    name: "Dashboard",
    meta: {
      title: String(i18n.t("sidebar.dashboard")),
      trans: "sidebar.dashboard",
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue"),
  },
];
