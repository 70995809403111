import hasToken from "@/helper/hasToken";

export default (to: any, from: any, next: any) => {
  if (hasToken()) {
    next({
      name: "Home",
    });
  }
  return next();
};
