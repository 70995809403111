import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import { SET_PROCESS_STATUSES, SET_SELECTED_STUDENT } from "@/store/Registrations/types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_DATA_ITEM_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,

  [SET_SELECTED_STUDENT](state: State, selectedStudent: any) {
    Object.assign(state, { selectedStudent });
  },
  [SET_PROCESS_STATUSES](state: State, registrationProcessStatuses: any) {
    Object.assign(state, { registrationProcessStatuses });
  },
};
