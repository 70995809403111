import axios from "@/utils/axios";
import { IS_LOADING, SET_DATA_ITEM, SET_ERROR, SET_SUCCESS, SET_TEST_ITEM } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";

import { findOne, update } from "@/store/Crud/functions/actions";

const URL = "/financial-config";

export const findCurrent = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/current`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const updateCurrent = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .put(`${URL}/current`, data)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_TEST_ITEM, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  findOne,
  update,
  updateCurrent,
  findCurrent,
};
