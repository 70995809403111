import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
import {
  SET_STUDENT_QUESTION_ANSWER_METRICS,
  SET_STUDENT_QUESTION_ANSWER_METRICS_ERROR,
  SET_STUDENT_QUESTION_ANSWER_METRICS_LOADING,
  SET_STUDENT_QUESTION_ANSWER_METRICS_SUCCESS,
  SET_STUDENT_QUESTION_ANSWER_STATS,
  SET_STUDENT_QUESTION_ANSWER_STATS_ERROR,
  SET_STUDENT_QUESTION_ANSWER_STATS_LOADING,
  SET_STUDENT_QUESTION_ANSWER_STATS_SUCCESS,
} from "@/store/LearnStatus/types";
import { AxiosError, AxiosResponse } from "axios";

const URL_STUDENT_QUESTION_ANSWERS = "student-question-answers";

export const studentQuestionAnswerMetrics = async (store: any, studentEducationId: number): Promise<void> => {
  store.commit(SET_STUDENT_QUESTION_ANSWER_METRICS_LOADING, true);
  store.commit(SET_STUDENT_QUESTION_ANSWER_METRICS_SUCCESS, false);
  store.commit(SET_STUDENT_QUESTION_ANSWER_METRICS_ERROR, null);
  return await axios
    .get(`${URL_STUDENT_QUESTION_ANSWERS}/metrics-for-fsc?studentEducationId=${studentEducationId}`)
    .then((response: AxiosResponse) => {
      store.commit(SET_STUDENT_QUESTION_ANSWER_METRICS, response.data);
      store.commit(SET_STUDENT_QUESTION_ANSWER_METRICS_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_STUDENT_QUESTION_ANSWER_METRICS_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(SET_STUDENT_QUESTION_ANSWER_METRICS_LOADING, false);
    });
};

export const studentQuestionAnswerStats = async (store: any, studentEducationId: number): Promise<void> => {
  store.commit(SET_STUDENT_QUESTION_ANSWER_STATS_LOADING, true);
  store.commit(SET_STUDENT_QUESTION_ANSWER_STATS_SUCCESS, false);
  store.commit(SET_STUDENT_QUESTION_ANSWER_STATS_ERROR, null);
  return await axios
    .get(`${URL_STUDENT_QUESTION_ANSWERS}/stats-for-fsc?studentEducationId=${studentEducationId}`)
    .then((response: AxiosResponse) => {
      store.commit(SET_STUDENT_QUESTION_ANSWER_STATS, response.data);
      store.commit(SET_STUDENT_QUESTION_ANSWER_STATS_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_STUDENT_QUESTION_ANSWER_STATS_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(SET_STUDENT_QUESTION_ANSWER_STATS_LOADING, false);
    });
};

export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
  studentQuestionAnswerMetrics,
  studentQuestionAnswerStats,
};
