import i18n from "@/i18n";
import { MODULE_COMPANY, MODULE_COMPANY_WRITE } from "@/constants/Permissions";

export default [
  {
    path: "/instructors",
    name: "Instructors",
    meta: {
      title: String(i18n.t("sidebar.instructors")),
      trans: "sidebar.instructors",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "instructors" */ "@/views/Instructor/Instructors.vue"),
  },
  {
    path: "/instructors/create",
    name: "InstructorCreate",
    meta: {
      title: String(i18n.t("sidebar.instructors_new")),
      trans: "sidebar.instructors_new",
      permissions: [MODULE_COMPANY, MODULE_COMPANY_WRITE],
    },
    component: () => import(/* webpackChunkName: "instructors-create" */ "@/views/Instructor/InstructorCreate.vue"),
  },
  {
    path: "/instructors/edit/:id",
    name: "InstructorEdit",
    meta: {
      title: String(i18n.t("sidebar.instructors")),
      trans: "sidebar.instructors",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "instructors-edit" */ "@/views/Instructor/InstructorEdit.vue"),
  },
];
