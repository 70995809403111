import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import { SET_ARCHIVE_SUCCESS } from "@/store/Crud/functions/archived/mutations";
import {
  SET_BASIC_FEE_PRODUCTS,
  SET_BASIC_FEE_PRODUCTS_ERROR,
  SET_BASIC_FEE_PRODUCTS_LOADING,
  SET_BASIC_FEE_PRODUCTS_SUCCESS,
  SET_BOOK_AT_STUDENT_REGISTRATION_ALLOWED,
  SET_LEARNING_MATERIAL_PRODUCTS,
  SET_LEARNING_MATERIAL_PRODUCTS_ERROR,
  SET_LEARNING_MATERIAL_PRODUCTS_LOADING,
  SET_LEARNING_MATERIAL_PRODUCTS_SUCCESS,
} from "@/store/Product/types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_ARCHIVE_SUCCESS,
  SET_DATA_ITEM_LOADING,

  [SET_BOOK_AT_STUDENT_REGISTRATION_ALLOWED](state: State, isBookAtStudentRegistrationAllowed: boolean) {
    Object.assign(state, { isBookAtStudentRegistrationAllowed });
  },

  [SET_LEARNING_MATERIAL_PRODUCTS](state: State, learningMaterialProducts: Array<any>) {
    Object.assign(state, { learningMaterialProducts });
  },

  [SET_LEARNING_MATERIAL_PRODUCTS_ERROR](state: State, learningMaterialProductsError: Record<any, any> | null) {
    Object.assign(state, { learningMaterialProductsError });
  },

  [SET_LEARNING_MATERIAL_PRODUCTS_SUCCESS](state: State, learningMaterialProductsSuccess: boolean) {
    Object.assign(state, { learningMaterialProductsSuccess });
  },

  [SET_LEARNING_MATERIAL_PRODUCTS_LOADING](state: State, learningMaterialProductsLoading: boolean) {
    Object.assign(state, { learningMaterialProductsLoading });
  },

  [SET_BASIC_FEE_PRODUCTS](state: State, basicFeeProducts: Array<any>) {
    Object.assign(state, { basicFeeProducts });
  },

  [SET_BASIC_FEE_PRODUCTS_ERROR](state: State, basicFeeProductsError: Record<any, any> | null) {
    Object.assign(state, { basicFeeProductsError });
  },

  [SET_BASIC_FEE_PRODUCTS_SUCCESS](state: State, basicFeeProductsSuccess: boolean) {
    Object.assign(state, { basicFeeProductsSuccess });
  },

  [SET_BASIC_FEE_PRODUCTS_LOADING](state: State, basicFeeProductsLoading: boolean) {
    Object.assign(state, { basicFeeProductsLoading });
  },
};
