export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";

export const SET_STUDENT_LIST_ERROR = "SET_STUDENT_LIST_ERROR";
export const SET_STUDENT_LIST_SUCCESS = "SET_STUDENT_LIST_SUCCESS";
export const SET_STUDENT_LIST_LOADING = "SET_STUDENT_LIST_LOADING";
export const SET_STUDENT_LIST = "SET_STUDENT_LIST";
export const SET_STUDENT_TOTAL = "SET_STUDENT_TOTAL";

export const SET_OVERVIEW_INFO_ERROR = "SET_OVERVIEW_INFO_ERROR";
export const SET_OVERVIEW_INFO_SUCCESS = "SET_OVERVIEW_INFO_SUCCESS";
export const SET_OVERVIEW_INFO_LOADING = "SET_OVERVIEW_INFO_LOADING";
export const SET_OVERVIEW_INFO = "SET_OVERVIEW_INFO";

export const SET_CANCEL_INVOICE_ERROR = "SET_CANCEL_INVOICE_ERROR";
export const SET_CANCEL_INVOICE_SUCCESS = "SET_CANCEL_INVOICE_SUCCESS";
export const SET_CANCEL_INVOICE_LOADING = "SET_CANCEL_INVOICE_LOADING";
export const SET_CANCEL_INVOICE = "SET_CANCEL_INVOICE";

export const SET_COST_BEARER_STUDENT_LIST_ERROR = "SET_COST_BEARER_STUDENT_LIST_ERROR";
export const SET_COST_BEARER_STUDENT_LIST_SUCCESS = "SET_COST_BEARER_STUDENT_LIST_SUCCESS";
export const SET_COST_BEARER_STUDENT_LIST_LOADING = "SET_COST_BEARER_STUDENT_LIST_LOADING";
export const SET_COST_BEARER_STUDENT_LIST = "SET_COST_BEARER_STUDENT_LIST";

export const SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_ERROR = "SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_ERROR";
export const SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_SUCCESS = "SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_SUCCESS";
export const SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_LOADING = "SET_LIVE_PAY_PAYMENT_UPDATE_STATUS_LOADING";
