import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import { IS_AVATAR_LOADING, SET_MIME_TYPE } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [IS_AVATAR_LOADING](state: State, avatarIsLoading: boolean) {
    Object.assign(state, { avatarIsLoading });
  },

  [SET_MIME_TYPE](state: State, mimeType: string) {
    Object.assign(state, { mimeType });
  },
};
