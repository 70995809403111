import { ActionTree } from "vuex";
import { State } from "./state";
import { findAll, findOne, update, create } from "@/store/Crud/functions/actions";

export default <ActionTree<State, any>>{
  findOne,
  update,
  findAll,
  create,
};
