import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_ITEM, SET_DATE_LIST } from "./types";

export default <MutationTree<State>>{
  [IS_LOADING](state: State, isLoading: boolean) {
    Object.assign(state, { isLoading });
  },

  [SET_ERROR](state: State, error: Record<string, any>) {
    Object.assign(state, { error });
  },

  [SET_SUCCESS](state: State, success: Record<string, any>) {
    Object.assign(state, { success });
  },

  [SET_DATA_ITEM](state: State, dataItem: Record<string, any>) {
    Object.assign(state, { dataItem });
  },

  [SET_DATE_LIST](state: State, dataList: Record<string, any>) {
    Object.assign(state, { dataList });
  },
};
