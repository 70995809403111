import i18n from "@/i18n";
import { MODULE_COMPANY } from "@/constants/Permissions";

export default [
  {
    path: "/roles",
    name: "Roles",
    meta: {
      title: String(i18n.t("roles.roles")),
      trans: "roles.roles",
      permissions: [MODULE_COMPANY],
    },
    component: () => import(/* webpackChunkName: "roles" */ "@/views/Role/Roles.vue"),
  },
];
