import { CrudState } from "@/store/Crud/State";
import { IEmailTemplateCategory } from "@/interfaces/IEmailTemplateCategory";
import { IEmailTemplate } from "@/interfaces/IEmailTemplate";

export class State extends CrudState {
  mailboxData: Array<any> = [];
  totalItems = 0;
  emailFilter: Array<any> = [];
  mailboxFilter: Array<any> = [];
  selectedTargetEmails: Array<any> = [];
  selectedEmailContent: any = {};
  emailTemplateCategories: Array<IEmailTemplateCategory> = [];
  emailTemplatesByCategory: Array<any> = [
    {
      id: 1,
      name: "Template 1",
      subject: "Template 1 Subject",
      body: "<p><strong>Title</strong>Template 1 </p><p>Body</p>",
      templateCategoryId: 0,
    },
  ];
  forceSyncByEmailConfigIdsSuccess = false;
  forceSyncByEmailConfigIdsError: Record<any, any> | null = null;
  forceSyncByEmailConfigIdsLoading = false;
  selectedEmailTemplate: any = {};
  placeholders: Array<any> = [];
  emailTemplates: Array<IEmailTemplate> = [];
  student: any = {};
  blob: Record<any, any> | null = null;
  mimeType = "";
  costBearer: any = {};

  attachExamDocument = null;
}
