import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_ITEM, SET_THEORY_QUESTION, SET_WRONG_ANSWER } from "./types";
import axios from "@/utils/axios";

const URL = "/theory-questions";

export const findLearningModeByEducationId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/learning-mode-for-fsc?studentEducationId=${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findTheoryQuestionById = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/${options.theoryQuestionId}?studentEducationId=${options.studentEducationId}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_THEORY_QUESTION, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
  findLearningModeByEducationId,
  findTheoryQuestionById,
};
