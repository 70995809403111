import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_VEHICLE_BY_CURRENT_INSTRUCTOR, SET_VEHICLE_BY_INSTRUCTOR_AND_USAGE_AND_LICENSE_CLASS } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import queryString from "query-string";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";

import { SET_VEHICLE_USAGE_REQUIREMENT_BY_LICENSE_CLASS } from "@/store/Vehicle/types";
import { archive } from "@/store/Crud/functions/archived/actions";

const URL = "/vehicles";

// export const archive = async (store: any, data: any): Promise<void> => {
//   const queryBuilder = queryString.stringify(data);
//
//   return await axios
//     .post(`${URL}/archive?${queryBuilder}`)
//     .then(({ data }) => {
//       store.commit(IS_LOADING, false);
//       // store.commit(SET_DATA_ITEM, data);
//       store.commit(SET_SUCCESS, true);
//     })
//     .catch(({ response }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_ERROR, response.data);
//     });
// };

export const vehicleUsageRequirementsByLicenseClass = async (store: any, licenseClass: any): Promise<void> => {
  return await axios
    .get(`${URL}/vehicle-usage-requirements/license-class/${licenseClass}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_VEHICLE_USAGE_REQUIREMENT_BY_LICENSE_CLASS, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const vehiclesByInstructorAndUsageAndLicenseClass = async (store: any, filter: any): Promise<void> => {
  return await axios
    .get(`${URL}/instructor/${filter.instructorId}/usage/${filter.vehicleUsage}/license-class/${filter.licenseClass}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_VEHICLE_BY_INSTRUCTOR_AND_USAGE_AND_LICENSE_CLASS, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const vehiclesByCurrentInstructor = async (store: any): Promise<void> => {
  return await axios
    .get(`${URL}/by-instructor/current`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_VEHICLE_BY_CURRENT_INSTRUCTOR, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  archive,
  vehicleUsageRequirementsByLicenseClass,
  vehiclesByInstructorAndUsageAndLicenseClass,
  vehiclesByCurrentInstructor,
};
