import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId, getSelectedRowName } from "@/store/Crud/functions/getters";

export const getExams = (state: State) => state.exams;
export const getAvatarSuccess = (state: State) => state.avatarSuccess;
export const getAvatarError = (state: State) => state.avatarError;
export const getAvatarIsLoading = (state: State) => state.avatarIsLoading;
export const getStudentsByCourse = (state: State) => state.studentsByCourse;
export const getExamsCostBearer = (state: State) => state.examsCostBearer;
export const getDataItemCostBearer = (state: State) => state.dataItemCostBearer;
export const getIsLoadingCostBearer = (state: State) => state.isLoadingCostBearer;

export const getSendPasswordChangeError = (state: State) => state.sendPasswordChangeError;
export const getSendPasswordChangeSuccess = (state: State) => state.sendPasswordChangeSuccess;
export const getSendPasswordChangeLoading = (state: State) => state.sendPasswordChangeLoading;

export const getStudentEmails = (state: State) => state.studentEmails;
export const getStudentEmailsError = (state: State) => state.studentEmailsError;
export const getStudentEmailsLoading = (state: State) => state.studentEmailsLoading;

export const getStudentsByList = (state: State) => state.studentsByList;
export const getSelectedStudent = (state: State) => state.selectedStudent;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getExams,
  getAvatarSuccess,
  getAvatarError,
  getAvatarIsLoading,
  getStudentsByCourse,
  getExamsCostBearer,
  getDataItemCostBearer,
  getIsLoadingCostBearer,
  getSelectedRowName,
  getSendPasswordChangeError,
  getSendPasswordChangeSuccess,
  getSendPasswordChangeLoading,
  getStudentEmails,
  getStudentEmailsError,
  getStudentEmailsLoading,
  getStudentsByList,
  getSelectedStudent,
};
