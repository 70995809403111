import { ActionTree } from "vuex";
import { State } from "./state";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";
import {
  SET_UPLOAD_PARENT_SIGNATURE_LOADING,
  SET_UPLOAD_STUDENT_SIGNATURE_ERROR,
  SET_UPLOAD_STUDENT_SIGNATURE_LOADING,
  SET_UPLOAD_PARENT_SIGNATURE_ERROR,
  SET_UPLOAD_STUDENT_SIGNATURE_SUCCESS,
  SET_UPLOAD_PARENT_SIGNATURE_SUCCESS,
  SET_SIGNED_RESPONSE,
  SET_SIGNED_ERROR,
  SET_SIGNED_LOADING,
  SET_SIGNED_SUCCESS,
  SET_ADDONS_CHECKBOX_TEXT_LOADING,
  SET_ADDONS_CHECKBOX_TEXT_SUCCESS,
  SET_ADDONS_CHECKBOX_TEXT_ERROR,
  SET_ADDONS_CHECKBOX_TEXT_RESPONSE,
  SET_GDPR_URL_CHECKBOX_TEXT_LOADING,
  SET_GDPR_URL_CHECKBOX_TEXT_SUCCESS,
  SET_GDPR_URL_CHECKBOX_TEXT_ERROR,
  SET_GDPR_URL_CHECKBOX_TEXT_RESPONSE,
} from "./types";
import { IUploadSignature } from "@/interfaces/IUploadSignature";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

const URL = "/training-contract";

export const uploadStudentSignature = async (store: any, options: IUploadSignature): Promise<void> => {
  store.commit(SET_UPLOAD_STUDENT_SIGNATURE_LOADING, true);
  store.commit(SET_UPLOAD_STUDENT_SIGNATURE_SUCCESS, false);
  store.commit(SET_UPLOAD_STUDENT_SIGNATURE_ERROR, null);
  return await axios
    .post(`${URL}/upload-student-signature?studentEducationId=${options.studentEducationId}`, options.formData)
    .then(() => {
      store.commit(SET_UPLOAD_STUDENT_SIGNATURE_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_UPLOAD_STUDENT_SIGNATURE_ERROR, error);
    })
    .finally(() => {
      store.commit(SET_UPLOAD_STUDENT_SIGNATURE_LOADING, false);
    });
};

export const uploadParentSignature = async (store: any, options: IUploadSignature): Promise<void> => {
  store.commit(SET_UPLOAD_PARENT_SIGNATURE_LOADING, true);
  store.commit(SET_UPLOAD_PARENT_SIGNATURE_SUCCESS, false);
  store.commit(SET_UPLOAD_PARENT_SIGNATURE_ERROR, null);
  return await axios
    .post(`${URL}/upload-parent-signature?studentEducationId=${options.studentEducationId}`, options.formData)
    .then(() => {
      store.commit(SET_UPLOAD_PARENT_SIGNATURE_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_UPLOAD_PARENT_SIGNATURE_ERROR, error);
    })
    .finally(() => {
      store.commit(SET_UPLOAD_PARENT_SIGNATURE_LOADING, false);
    });
};
export const createSigned = async (store: any, options: ICrudOptions): Promise<void> => {
  store.commit(SET_SIGNED_LOADING, true);
  store.commit(SET_SIGNED_SUCCESS, false);
  store.commit(SET_SIGNED_ERROR, null);
  store.commit(SET_SIGNED_RESPONSE, null);
  return await axios
    .post(options.resource, options.data, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    })
    .then((response: AxiosResponse) => {
      store.commit(SET_SIGNED_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      const blob = new Blob([response.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      store.commit(SET_SIGNED_RESPONSE, url);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_SIGNED_ERROR, error);
    })
    .finally(() => {
      store.commit(SET_SIGNED_LOADING, false);
    });
};

export const addonsCheckboxText = async (store: any, studentEducationId: number): Promise<void> => {
  store.commit(SET_ADDONS_CHECKBOX_TEXT_LOADING, true);
  store.commit(SET_ADDONS_CHECKBOX_TEXT_SUCCESS, false);
  store.commit(SET_ADDONS_CHECKBOX_TEXT_ERROR, null);
  return await axios
    .get(`${URL}/get-addons-checkbox-text?studentEducationId=${studentEducationId}`)
    .then((response: AxiosResponse) => {
      store.commit(SET_ADDONS_CHECKBOX_TEXT_SUCCESS, true);
      store.commit(SET_ADDONS_CHECKBOX_TEXT_RESPONSE, response.data);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_ADDONS_CHECKBOX_TEXT_ERROR, error);
    })
    .finally(() => {
      store.commit(SET_ADDONS_CHECKBOX_TEXT_LOADING, false);
    });
};

export const gdprUrlCheckboxText = async (store: any, studentEducationId: number): Promise<void> => {
  store.commit(SET_GDPR_URL_CHECKBOX_TEXT_LOADING, true);
  store.commit(SET_GDPR_URL_CHECKBOX_TEXT_SUCCESS, false);
  store.commit(SET_GDPR_URL_CHECKBOX_TEXT_ERROR, null);
  return await axios
    .get(`${URL}/get-gdpr-url-checkbox-text?studentEducationId=${studentEducationId}`)
    .then((response: AxiosResponse) => {
      store.commit(SET_GDPR_URL_CHECKBOX_TEXT_SUCCESS, true);
      store.commit(SET_GDPR_URL_CHECKBOX_TEXT_RESPONSE, response.data);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_GDPR_URL_CHECKBOX_TEXT_ERROR, error);
    })
    .finally(() => {
      store.commit(SET_GDPR_URL_CHECKBOX_TEXT_LOADING, false);
    });
};

export default <ActionTree<State, any>>{
  uploadStudentSignature,
  uploadParentSignature,
  createSigned,
  addonsCheckboxText,
  gdprUrlCheckboxText,
};
