export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_BOOK_AT_STUDENT_REGISTRATION_ALLOWED = "SET_BOOK_AT_STUDENT_REGISTRATION_ALLOWED";

export const SET_LEARNING_MATERIAL_PRODUCTS = "SET_LEARNING_MATERIAL_PRODUCTS";
export const SET_LEARNING_MATERIAL_PRODUCTS_ERROR = "SET_LEARNING_MATERIAL_PRODUCTS_ERROR";
export const SET_LEARNING_MATERIAL_PRODUCTS_SUCCESS = "SET_LEARNING_MATERIAL_PRODUCTS_SUCCESS";
export const SET_LEARNING_MATERIAL_PRODUCTS_LOADING = "SET_LEARNING_MATERIAL_PRODUCTS_LOADING";

export const SET_BASIC_FEE_PRODUCTS = "SET_BASIC_FEE_PRODUCTS";
export const SET_BASIC_FEE_PRODUCTS_ERROR = "SET_BASIC_FEE_PRODUCTS_ERROR";
export const SET_BASIC_FEE_PRODUCTS_SUCCESS = "SET_BASIC_FEE_PRODUCTS_SUCCESS";
export const SET_BASIC_FEE_PRODUCTS_LOADING = "SET_BASIC_FEE_PRODUCTS_LOADING";
