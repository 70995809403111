import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_FILE_ERROR,
  SET_FILE_LOADING,
  SET_FILE_RESPONSE,
  SET_DATA_ITEM_LOADING,
  SET_FILE_SUCCESS,
  SET_SELECTED_ROW_NAME,
} from "@/store/Crud/functions/mutations";
import { SET_ARCHIVE_SUCCESS } from "@/store/Crud/functions/archived/mutations";

import {
  SET_EDUCATIONS_LIST,
  SET_SALDO_LIST,
  SET_SALDO_LIST_LOADING,
  SET_MIME_TYPE,
  SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE,
  SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_ERROR,
  SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_SUCCESS,
  SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_LOADING,
} from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  SET_FILE_ERROR,
  SET_FILE_LOADING,
  SET_FILE_RESPONSE,
  SET_FILE_SUCCESS,
  SET_ARCHIVE_SUCCESS,
  SET_SELECTED_ROW_NAME,

  [SET_EDUCATIONS_LIST](state: State, educationsByCostBearer: any) {
    Object.assign(state, { educationsByCostBearer });
  },
  [SET_SALDO_LIST](state: State, saldoByCostBearer: any) {
    Object.assign(state, { saldoByCostBearer });
  },

  [SET_SALDO_LIST_LOADING](state: State, saldoByCostBearerLoading: boolean) {
    Object.assign(state, { saldoByCostBearerLoading });
  },

  [SET_MIME_TYPE](state: State, mimeType: string) {
    Object.assign(state, { mimeType });
  },

  [SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE](state: State, generateCostBearerClassicPayCustomBookingInvoice: any) {
    Object.assign(state, { generateCostBearerClassicPayCustomBookingInvoice });
  },
  [SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_ERROR](state: State, generateCostBearerClassicPayCustomBookingInvoiceError: any) {
    Object.assign(state, { generateCostBearerClassicPayCustomBookingInvoiceError });
  },
  [SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_SUCCESS](state: State, generateCostBearerClassicPayCustomBookingInvoiceSuccess: boolean) {
    Object.assign(state, { generateCostBearerClassicPayCustomBookingInvoiceSuccess });
  },
  [SET_GENERATE_COST_BEARER_CLASSIC_PAY_CUSTOM_BOOKING_INVOICE_LOADING](state: State, generateCostBearerClassicPayCustomBookingInvoiceLoading: boolean) {
    Object.assign(state, { generateCostBearerClassicPayCustomBookingInvoiceLoading });
  },
};
