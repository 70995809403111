import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getInstructorCheckWorkingHours = (state: State) => state.instructorCheckWorkingHours;
export const getInstructorCheckWorkingHoursSuccess = (state: State) => state.instructorCheckWorkingHoursSuccess;
export const getInstructorCheckWorkingHoursError = (state: State) => state.instructorCheckWorkingHoursError;
export const getInstructorCheckWorkingHoursLoading = (state: State) => state.instructorCheckWorkingHoursLoading;

export const getInstructorCheckOverlappingAppointments = (state: State) => state.instructorCheckOverlappingAppointments;
export const getInstructorCheckOverlappingAppointmentsSuccess = (state: State) => state.instructorCheckOverlappingAppointmentsSuccess;
export const getInstructorCheckOverlappingAppointmentsError = (state: State) => state.instructorCheckOverlappingAppointmentsError;
export const getInstructorCheckOverlappingAppointmentsLoading = (state: State) => state.instructorCheckOverlappingAppointmentsLoading;

export const getInstructorCheckWorkingHoursRepeatingEvent = (state: State) => state.instructorCheckWorkingHoursRepeatingEvent;
export const getInstructorCheckWorkingHoursRepeatingEventSuccess = (state: State) => state.instructorCheckWorkingHoursRepeatingEventSuccess;
export const getInstructorCheckWorkingHoursRepeatingEventError = (state: State) => state.instructorCheckWorkingHoursRepeatingEventError;
export const getInstructorCheckWorkingHoursRepeatingEventLoading = (state: State) => state.instructorCheckWorkingHoursRepeatingEventLoading;

export const getInstructorCheckOverlappingAppointmentsRepeatingEvent = (state: State) => state.instructorCheckOverlappingAppointmentsRepeatingEvent;
export const getInstructorCheckOverlappingAppointmentsRepeatingEventSuccess = (state: State) =>
  state.instructorCheckOverlappingAppointmentsRepeatingEventSuccess;
export const getInstructorCheckOverlappingAppointmentsRepeatingEventError = (state: State) => state.instructorCheckOverlappingAppointmentsRepeatingEventError;
export const getInstructorCheckOverlappingAppointmentsRepeatingEventLoading = (state: State) =>
  state.instructorCheckOverlappingAppointmentsRepeatingEventLoading;

export const getVehicleCheckOverlappingAppointments = (state: State) => state.vehicleCheckOverlappingAppointments;
export const getVehicleCheckOverlappingAppointmentsSuccess = (state: State) => state.vehicleCheckOverlappingAppointmentsSuccess;
export const getVehicleCheckOverlappingAppointmentsError = (state: State) => state.vehicleCheckOverlappingAppointmentsError;
export const getVehicleCheckOverlappingAppointmentsLoading = (state: State) => state.vehicleCheckOverlappingAppointmentsLoading;

export const getVehicleCheckOverlappingAppointmentsRepeatingEvent = (state: State) => state.vehicleCheckOverlappingAppointmentsRepeatingEvent;
export const getVehicleCheckOverlappingAppointmentsRepeatingEventSuccess = (state: State) => state.vehicleCheckOverlappingAppointmentsRepeatingEventSuccess;
export const getVehicleCheckOverlappingAppointmentsRepeatingEventError = (state: State) => state.vehicleCheckOverlappingAppointmentsRepeatingEventError;
export const getVehicleCheckOverlappingAppointmentsRepeatingEventLoading = (state: State) => state.vehicleCheckOverlappingAppointmentsRepeatingEventLoading;

export const getVehicleCheckOverlappingAppointmentsAsArray = (state: State) => state.vehicleCheckOverlappingAppointmentsAsArray;
export const getVehicleCheckOverlappingAppointmentsAsArraySuccess = (state: State) => state.vehicleCheckOverlappingAppointmentsAsArraySuccess;
export const getVehicleCheckOverlappingAppointmentsAsArrayError = (state: State) => state.vehicleCheckOverlappingAppointmentsAsArrayError;
export const getVehicleCheckOverlappingAppointmentsAsArrayLoading = (state: State) => state.vehicleCheckOverlappingAppointmentsAsArrayLoading;

export const getDashBoardUserCalendar = (state: State) => state.dashBoardUserCalendar;
export const getDashBoardUserLoadingCalendar = (state: State) => state.dashBoardUserLoadingCalendar;
export const getDashBoardUserErrorCalendar = (state: State) => state.dashBoardUserErrorCalendar;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,

  // check instructor working hours
  getInstructorCheckWorkingHours,
  getInstructorCheckWorkingHoursSuccess,
  getInstructorCheckWorkingHoursError,
  getInstructorCheckWorkingHoursLoading,

  // check instructor overlapping appointments
  getInstructorCheckOverlappingAppointments,
  getInstructorCheckOverlappingAppointmentsSuccess,
  getInstructorCheckOverlappingAppointmentsError,
  getInstructorCheckOverlappingAppointmentsLoading,

  // check instructor working hours repeating event
  getInstructorCheckWorkingHoursRepeatingEvent,
  getInstructorCheckWorkingHoursRepeatingEventSuccess,
  getInstructorCheckWorkingHoursRepeatingEventError,
  getInstructorCheckWorkingHoursRepeatingEventLoading,

  // check instructor overlapping appointments repeating event
  getInstructorCheckOverlappingAppointmentsRepeatingEvent,
  getInstructorCheckOverlappingAppointmentsRepeatingEventSuccess,
  getInstructorCheckOverlappingAppointmentsRepeatingEventError,
  getInstructorCheckOverlappingAppointmentsRepeatingEventLoading,

  // check vehicle overlapping appointments
  getVehicleCheckOverlappingAppointments,
  getVehicleCheckOverlappingAppointmentsSuccess,
  getVehicleCheckOverlappingAppointmentsError,
  getVehicleCheckOverlappingAppointmentsLoading,

  // check vehicle overlapping appointments repeating event
  getVehicleCheckOverlappingAppointmentsRepeatingEvent,
  getVehicleCheckOverlappingAppointmentsRepeatingEventSuccess,
  getVehicleCheckOverlappingAppointmentsRepeatingEventError,
  getVehicleCheckOverlappingAppointmentsRepeatingEventLoading,

  // check vehicle overlapping appointments as array
  getVehicleCheckOverlappingAppointmentsAsArray,
  getVehicleCheckOverlappingAppointmentsAsArraySuccess,
  getVehicleCheckOverlappingAppointmentsAsArrayError,
  getVehicleCheckOverlappingAppointmentsAsArrayLoading,

  // dashboard
  getDashBoardUserCalendar,
  getDashBoardUserLoadingCalendar,
  getDashBoardUserErrorCalendar,
};
