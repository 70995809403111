import { Module } from "vuex";
import { PaymentWorkflowState } from "./state";
import Mutations from "./mutations";
import Getters from "./getters";
import Actions from "./actions";

export default class PaymentWorkflow implements Module<PaymentWorkflowState, any> {
  namespaced = true;
  state: PaymentWorkflowState;
  mutations = Mutations;
  getters = Getters;
  actions = Actions;

  constructor() {
    this.state = new PaymentWorkflowState();
  }
}
