import { GetterTree } from "vuex";
import { State } from "./state";

export function getContent(state: State): any {
  return state.content;
}

export function getStatus(state: State): boolean {
  return state.status;
}
export default <GetterTree<State, any>>{
  getContent,
  getStatus,
};
