import i18n from "@/i18n";
import { MODULE_COST_BEARERS } from "@/constants/Permissions";

export default [
  {
    path: "/payers",
    name: "CostBearers",
    meta: {
      title: String(i18n.t("students.payers")),
      trans: "students.payers",
      permissions: [MODULE_COST_BEARERS],
    },
    component: () => import(/* webpackChunkName: "scheduler" */ "@/views/CostBearers/CostBearers.vue"),
  },
  {
    path: "/payers/upload-qrcode-document",
    name: "CostBearerUploadQrcodeDocument",
    meta: {
      title: String(i18n.t("students.payers")),
      trans: "students.payers",
      permissions: [MODULE_COST_BEARERS],
    },
    component: () => import(/* webpackChunkName: "cost-bearer-upload-qrcode-document" */ "@/views/CostBearers/CostBearerUploadQrcodeDocument.vue"),
  },
  {
    path: "/payers/services/:id",
    name: "CostBearerServices",
    meta: {
      title: String(i18n.t("students.services")),
      trans: "students.services",
      permissions: [MODULE_COST_BEARERS],
    },
    component: () => import(/* webpackChunkName: "cost-bearer-services" */ "@/views/CostBearers/Services/CostBearerServices.vue"),
    props: true,
  },
  {
    path: "/payers/classic-pay/:id",
    name: "CostBearerClassicPay",
    meta: {
      title: String(i18n.t("automatic_payment.classic_pay")),
      trans: "automatic_payment.classic_pay",
      permissions: [MODULE_COST_BEARERS],
    },
    component: () => import(/* webpackChunkName: "cost-bearer-classic-pay" */ "@/views/CostBearers/CostBearerClassicPay.vue"),
  },
  {
    path: "/payers/payment-request",
    name: "CostBearerPaymentRequest",
    meta: {
      title: String(i18n.t("automatic_payment.payment_request")),
      trans: "automatic_payment.payment_request",
      permissions: [MODULE_COST_BEARERS],
    },
    component: () => import(/* webpackChunkName: "cost-bearer-payment-request" */ "@/views/CostBearers/CostBearerPaymentRequest.vue"),
    props: true,
  },
  {
    path: "/payers/deposit-invoice",
    name: "CostBearDepositInvoice",
    meta: {
      title: String(i18n.t("invoice.azr")),
      trans: "invoice.azr",
      permissions: [MODULE_COST_BEARERS],
    },
    component: () => import(/* webpackChunkName: "cost-bearer-deposit-invoice" */ "@/views/CostBearers/CostBearDepositInvoice.vue"),
    props: true,
  },
];
