import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { $axios } from "@/utils/axios";
import store from "@/store";

export async function getFile(config: AxiosRequestConfig, showModal = true, showError = true, returnBlob = false): Promise<any> {
  return await $axios({
    ...config,
    responseType: "arraybuffer",
  })
    .then(async (response: AxiosResponse) => {
      const mimeType = response.headers["content-type"].toLowerCase();
      const blob = new Blob([response.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      if (showModal) {
        await store.dispatch("modal/setContent", url, { root: true });
        await store.dispatch("modal/setStatus", true, { root: true });
      }
      if (returnBlob) {
        return { blob: blob, mimeType, data: response.data };
      }
      return url;
    })
    .catch(async (error: AxiosError) => {
      if (showError) {
        await dispatchError(error);
      }
      return Promise.reject(error);
    });
}

export async function getFileWithBlob(config: AxiosRequestConfig, showModal = true): Promise<any> {
  return await $axios({
    ...config,
    responseType: "arraybuffer",
  })
    .then(async (response: AxiosResponse) => {
      const mimeType = response.headers["content-type"].toLowerCase();
      const blob = new Blob([response.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      if (showModal) {
        await store.dispatch("modal/setContent", url, { root: true });
        await store.dispatch("modal/setStatus", true, { root: true });
      }
      return {
        mimeType: mimeType,
        blob: blob,
        url: url,
      };
    })
    .catch(async (error: AxiosError) => {
      await dispatchError(error);
      return Promise.reject(error);
    });
}

export function dataURLtoFile(dataurl: any, filename: any) {
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function downloadFile(config: AxiosRequestConfig, filename = "export.csv"): Promise<any> {
  return await $axios({
    ...config,
    responseType: "blob",
  })
    .then(async (response: AxiosResponse) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error: AxiosError) => {
      dispatchError(error);
    });
}

export async function downloadXlsXFile(config: AxiosRequestConfig, filename = "export.xlsx"): Promise<any> {
  return await $axios({
    ...config,
    responseType: "blob",
  })
    .then(async (response: AxiosResponse) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error: AxiosError) => {
      dispatchError(error);
    });
}

async function dispatchError(error: AxiosError): Promise<any> {
  if (error.response?.status === 422 || error.response?.status === 500) {
    const blob = new Blob([error.response.data], {
      type: "application/json",
    });
    const fr = new FileReader();

    fr.onload = () => {
      //@ts-ignore
      const responseError = JSON.parse(fr.result);
      const errorMessage =
        responseError.globalErrors && responseError.globalErrors.length > 0
          ? responseError.globalErrors[0]
          : responseError.fieldErrors && responseError.fieldErrors.length > 0
          ? responseError.fieldErrors.map((e: any) => e.error)[0]
          : "Unknown error";
      store.dispatch(
        "snackbar/setMessage",
        {
          text: error.response?.status === 500 ? responseError.error : errorMessage,
          type: "error",
        },
        { root: true }
      );
    };

    fr.readAsText(blob);
  } else {
    await store.dispatch(
      "snackbar/setMessage",
      {
        text: error.message,
        type: "error",
      },
      { root: true }
    );
  }
}
