import { PaymentWorkflowState } from "./state";
import { GetterTree } from "vuex";

import { getDataList } from "@/store/Crud/functions/getters";

export const getStudentList = (state: PaymentWorkflowState) => state.studentList;
export const getStudentListError = (state: PaymentWorkflowState) => state.studentListError;
export const getStudentListSuccess = (state: PaymentWorkflowState) => state.studentListSuccess;
export const getStudentListLoading = (state: PaymentWorkflowState) => state.studentListLoading;
export const getStudentListTotal = (state: PaymentWorkflowState) => state.studentListTotal;

export const getOverviewInfo = (state: PaymentWorkflowState) => state.overviewInfo;
export const getOverviewInfoError = (state: PaymentWorkflowState) => state.overviewInfoError;
export const getOverviewInfoSuccess = (state: PaymentWorkflowState) => state.overviewInfoSuccess;
export const getOverviewInfoLoading = (state: PaymentWorkflowState) => state.overviewInfoLoading;

export const getCancelInvoice = (state: PaymentWorkflowState) => state.cancelInvoice;
export const getCancelInvoiceError = (state: PaymentWorkflowState) => state.cancelInvoiceError;
export const getCancelInvoiceSuccess = (state: PaymentWorkflowState) => state.cancelInvoiceSuccess;
export const getCancelInvoiceLoading = (state: PaymentWorkflowState) => state.cancelInvoiceLoading;

export const getCostBearerStudentList = (state: PaymentWorkflowState) => state.costBearerStudentList;
export const getCostBearerStudentListError = (state: PaymentWorkflowState) => state.costBearerStudentListError;
export const getCostBearerStudentListSuccess = (state: PaymentWorkflowState) => state.costBearerStudentListSuccess;
export const getCostBearerStudentListLoading = (state: PaymentWorkflowState) => state.costBearerStudentListLoading;

export default <GetterTree<PaymentWorkflowState, any>>{
  getDataList,
  getStudentList,
  getStudentListError,
  getStudentListSuccess,
  getStudentListLoading,
  getOverviewInfo,
  getOverviewInfoError,
  getOverviewInfoSuccess,
  getOverviewInfoLoading,
  getCancelInvoice,
  getCancelInvoiceError,
  getCancelInvoiceSuccess,
  getCancelInvoiceLoading,
  getCostBearerStudentList,
  getCostBearerStudentListError,
  getCostBearerStudentListSuccess,
  getCostBearerStudentListLoading,
  getStudentListTotal,
};
