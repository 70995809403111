import { CrudState } from "@/store/Crud/State";

export class State extends CrudState {
  // check instructor working hours
  instructorCheckWorkingHours: boolean | null = null;
  instructorCheckWorkingHoursSuccess = false;
  instructorCheckWorkingHoursLoading = false;
  instructorCheckWorkingHoursError: Record<any, any> | null = null;

  // check instructor overlapping appointments
  instructorCheckOverlappingAppointments: boolean | null = null;
  instructorCheckOverlappingAppointmentsSuccess = false;
  instructorCheckOverlappingAppointmentsLoading = false;
  instructorCheckOverlappingAppointmentsError: Record<any, any> | null = null;

  // check instructor working hours repeating event
  instructorCheckWorkingHoursRepeatingEvent: boolean | null = null;
  instructorCheckWorkingHoursRepeatingEventSuccess = false;
  instructorCheckWorkingHoursRepeatingEventLoading = false;
  instructorCheckWorkingHoursRepeatingEventError: Record<any, any> | null = null;

  // check instructor overlapping appointments repeating event
  instructorCheckOverlappingAppointmentsRepeatingEvent: boolean | null = null;
  instructorCheckOverlappingAppointmentsRepeatingEventSuccess = false;
  instructorCheckOverlappingAppointmentsRepeatingEventLoading = false;
  instructorCheckOverlappingAppointmentsRepeatingEventError: Record<any, any> | null = null;

  // check vehicle overlapping appointments
  vehicleCheckOverlappingAppointments: boolean | null = null;
  vehicleCheckOverlappingAppointmentsSuccess = false;
  vehicleCheckOverlappingAppointmentsLoading = false;
  vehicleCheckOverlappingAppointmentsError: Record<any, any> | null = null;

  // check vehicle overlapping appointments repeating event
  vehicleCheckOverlappingAppointmentsRepeatingEvent: boolean | null = null;
  vehicleCheckOverlappingAppointmentsRepeatingEventSuccess = false;
  vehicleCheckOverlappingAppointmentsRepeatingEventLoading = false;
  vehicleCheckOverlappingAppointmentsRepeatingEventError: Record<any, any> | null = null;

  // check vehicle overlapping appointments as array
  vehicleCheckOverlappingAppointmentsAsArray: boolean | null = null;
  vehicleCheckOverlappingAppointmentsAsArraySuccess = false;
  vehicleCheckOverlappingAppointmentsAsArrayLoading = false;
  vehicleCheckOverlappingAppointmentsAsArrayError: Record<any, any> | null = null;

  dashBoardUserCalendar: Array<any> = [];
  dashBoardUserLoadingCalendar = false;
  dashBoardUserErrorCalendar: Record<any, any> | null = null;
}
