import { ActionTree } from "vuex";
import { State } from "./state";
import { findAll } from "@/store/Crud/functions/actions";
import { findAllStudentList, findOverviewInfo } from "@/store/PaymentWorkflow/actions";

export default <ActionTree<State, any>>{
  findAll,
  findAllStudentList,
  findOverviewInfo,
};
