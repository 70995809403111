export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_DATA_EXAM = "SET_DATA_EXAM";
export const IS_AVATAR_LOADING = "IS_AVATAR_LOADING";
export const SET_AVATAR_SUCCESS = "SET_AVATAR_SUCCESS";
export const SET_AVATAR_ERROR = "SET_AVATAR_ERROR";
export const SET_DATA_LIST_BY_COURSE = "SET_DATA_LIST_BY_COURSE";
export const SET_DATA_ITEM_COST_BEARER = "SET_DATA_ITEM_COST_BEARER";
export const SET_DATA_EXAM_COST_BEARER = "SET_DATA_EXAM_COST_BEARER";
export const IS_LOADING_COST_BEARER = "IS_LOADING_COST_BEARER";

export const SET_SEND_PASSWORD_CHANGE_SUCCESS = "SET_SEND_PASSWORD_CHANGE_SUCCESS";
export const SET_SEND_PASSWORD_CHANGE_ERROR = "SET_SEND_PASSWORD_CHANGE_ERROR";
export const SET_SEND_PASSWORD_CHANGE_LOADING = "SET_SEND_PASSWORD_CHANGE_LOADING";

export const SET_STUDENT_EMAILS = "SET_STUDENT_EMAILS";
export const SET_STUDENT_EMAILS_ERROR = "SET_STUDENT_EMAILS_ERROR";
export const SET_STUDENT_EMAILS_LOADING = "SET_STUDENT_EMAILS_LOADING";

export const SET_DATA_LIST_BY_LIST = "SET_DATA_LIST_BY_LIST";
