export function tryParseToJSONObject(jsonString: string | null) {
  if (!jsonString) {
    return false;
  }

  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {
    //
  }
  return false;
}
