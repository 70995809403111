import { ActionTree } from "vuex";
import { State } from "./state";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_PRICELIST_COPY } from "./types";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";
// @ts-ignore
import queryString from "query-string";
import { archive } from "@/store/Crud/functions/archived/actions";
import i18n from "@/i18n";

const URL = "/price-lists";
const URL_COPY = "/price-lists/copy-pricelist";

export const copyPriceList = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL_COPY}/${data.id}?name=${data.name}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_PRICELIST_COPY, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};
export const priceListRename = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  const url = `${URL}/${data.id}/rename/${data.name}`;
  const options: any = { hideSuccessMessage: true };
  return await axios
    .put(url, data, options)
    .then(() => {
      store.commit(SET_SUCCESS, true);
      const message = String(i18n.t("messages.operation_successful"));
      return store.dispatch(
        "snackbar/setMessage",
        {
          text: message,
          type: "success",
        },
        { root: true }
      );
    })
    .catch((e: any) => {
      store.commit(SET_ERROR, e);
      store.commit(IS_LOADING, false);
    });
};
export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  archive,
  findAll,
  copyPriceList,
  priceListRename,
};
