import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getUploadParentSignatureError = (state: State) => state.uploadParentSignatureError;
export const getUploadParentSignatureSuccess = (state: State) => state.uploadParentSignatureSuccess;
export const getUploadParentSignatureLoading = (state: State) => state.uploadParentSignatureLoading;

export const getUploadStudentSignatureError = (state: State) => state.uploadStudentSignatureError;
export const getUploadStudentSignatureSuccess = (state: State) => state.uploadStudentSignatureSuccess;
export const getUploadStudentSignatureLoading = (state: State) => state.uploadStudentSignatureLoading;

export const getSignedError = (state: State) => state.signedError;
export const getSignedSuccess = (state: State) => state.signedSuccess;
export const getSignedLoading = (state: State) => state.signedLoading;
export const getSignedResponse = (state: State) => state.signedResponse;

export const getAddonsCheckboxTextError = (state: State) => state.addonsCheckboxTextError;
export const getAddonsCheckboxTextSuccess = (state: State) => state.addonsCheckboxTextSuccess;
export const getAddonsCheckboxTextLoading = (state: State) => state.addonsCheckboxTextLoading;
export const getAddonsCheckboxTextResponse = (state: State) => state.addonsCheckboxTextResponse;

export const getGdprUrlCheckboxTextError = (state: State) => state.gdprUrlCheckboxTextError;
export const getGdprUrlCheckboxTextSuccess = (state: State) => state.gdprUrlCheckboxTextSuccess;
export const getGdprUrlCheckboxTextLoading = (state: State) => state.gdprUrlCheckboxTextLoading;
export const getGdprUrlCheckboxTextResponse = (state: State) => state.gdprUrlCheckboxTextResponse;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getUploadParentSignatureError,
  getUploadParentSignatureSuccess,
  getUploadParentSignatureLoading,
  getUploadStudentSignatureError,
  getUploadStudentSignatureSuccess,
  getUploadStudentSignatureLoading,
  getSignedError,
  getSignedSuccess,
  getSignedLoading,
  getSignedResponse,
  getAddonsCheckboxTextError,
  getAddonsCheckboxTextSuccess,
  getAddonsCheckboxTextLoading,
  getAddonsCheckboxTextResponse,
  getGdprUrlCheckboxTextError,
  getGdprUrlCheckboxTextSuccess,
  getGdprUrlCheckboxTextLoading,
  getGdprUrlCheckboxTextResponse,
};
