import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

import { getArchiveSuccess } from "@/store/Crud/functions/archived/getters";

export function getIsBookAtStudentRegistrationAllowed(state: State): any {
  return state.isBookAtStudentRegistrationAllowed;
}

export function getLearningMaterialProducts(state: State): any {
  return state.learningMaterialProducts;
}

export function getLearningMaterialProductsSuccess(state: State): any {
  return state.learningMaterialProductsSuccess;
}

export function getLearningMaterialProductsError(state: State): any {
  return state.learningMaterialProductsError;
}

export function getLearningMaterialProductsLoading(state: State): any {
  return state.learningMaterialProductsLoading;
}

export function getBasicFeeProducts(state: State): any {
  return state.basicFeeProducts;
}

export function getBasicFeeSuccess(state: State): any {
  return state.basicFeeProductsSuccess;
}

export function getBasicFeeError(state: State): any {
  return state.basicFeeProductsError;
}

export function getBasicFeeLoading(state: State): any {
  return state.basicFeeProductsLoading;
}

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getArchiveSuccess,
  getId,
  getIsBookAtStudentRegistrationAllowed,
  getLearningMaterialProducts,
  getLearningMaterialProductsSuccess,
  getLearningMaterialProductsError,
  getLearningMaterialProductsLoading,
  getBasicFeeProducts,
  getBasicFeeSuccess,
  getBasicFeeError,
  getBasicFeeLoading,
};
