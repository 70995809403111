import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";
import {
  SET_STUDENT_QUESTION_ANSWER_METRICS,
  SET_STUDENT_QUESTION_ANSWER_METRICS_ERROR,
  SET_STUDENT_QUESTION_ANSWER_METRICS_LOADING,
  SET_STUDENT_QUESTION_ANSWER_METRICS_SUCCESS,
  SET_STUDENT_QUESTION_ANSWER_STATS,
  SET_STUDENT_QUESTION_ANSWER_STATS_ERROR,
  SET_STUDENT_QUESTION_ANSWER_STATS_LOADING,
  SET_STUDENT_QUESTION_ANSWER_STATS_SUCCESS,
} from "@/store/LearnStatus/types";
import { IStudentQuestionAnswerMetrics } from "@/interfaces/IStudentQuestionAnswerMetrics";
import { IStudentQuestionAnswerStats } from "@/interfaces/IStudentQuestionAnswerStats";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_STUDENT_QUESTION_ANSWER_METRICS](state: State, studentQuestionAnswerMetrics: IStudentQuestionAnswerMetrics | null) {
    Object.assign(state, { studentQuestionAnswerMetrics });
  },
  [SET_STUDENT_QUESTION_ANSWER_METRICS_SUCCESS](state: State, studentQuestionAnswerMetricsSuccess: boolean) {
    Object.assign(state, { studentQuestionAnswerMetricsSuccess });
  },
  [SET_STUDENT_QUESTION_ANSWER_METRICS_ERROR](state: State, studentQuestionAnswerMetricsError: Record<any, any> | null) {
    Object.assign(state, { studentQuestionAnswerMetricsError });
  },
  [SET_STUDENT_QUESTION_ANSWER_METRICS_LOADING](state: State, studentQuestionAnswerMetricsLoading: boolean) {
    Object.assign(state, { studentQuestionAnswerMetricsLoading });
  },

  [SET_STUDENT_QUESTION_ANSWER_STATS](state: State, studentQuestionAnswerStats: IStudentQuestionAnswerStats | null) {
    Object.assign(state, { studentQuestionAnswerStats });
  },
  [SET_STUDENT_QUESTION_ANSWER_STATS_SUCCESS](state: State, studentQuestionAnswerStatsSuccess: boolean) {
    Object.assign(state, { studentQuestionAnswerStatsSuccess });
  },
  [SET_STUDENT_QUESTION_ANSWER_STATS_ERROR](state: State, studentQuestionAnswerStatsError: Record<any, any> | null) {
    Object.assign(state, { studentQuestionAnswerStatsError });
  },
  [SET_STUDENT_QUESTION_ANSWER_STATS_LOADING](state: State, studentQuestionAnswerStatsLoading: boolean) {
    Object.assign(state, { studentQuestionAnswerStatsLoading });
  },
};
