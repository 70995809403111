import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll, del } from "@/store/Crud/functions/actions";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IS_LOADING, SET_ERROR, SET_DATA_LIST, SET_SUCCESS, SET_COURSES_AND_THEORY_LESSONS, SET_UPCOMING_THEORY_COURSES } from "./types";
import { ITheoryCourseDateFilter } from "@/interfaces/ITheoryCourseDateFilter";
import { Vue } from "vue-property-decorator";
import i18n from "@/i18n";

const URL = "/theory-courses";

export const findUpcomingTheoryCourses = async (store: ActionContext<any, any>) => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/upcoming-five-theory-courses`)
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_UPCOMING_THEORY_COURSES, response.data);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error.response?.data);
    });
};

export const findCoursesAndTheoryLessons = async (store: ActionContext<any, any>, filter: ITheoryCourseDateFilter) => {
  store.commit("IS_LOADING", true);

  return await axios
    .post(`${URL}/theory-list/filter`, filter)
    .then((response: AxiosResponse) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_COURSES_AND_THEORY_LESSONS", response.data);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", error.response?.data);
    });
};

export const updateTheoryStudents = async (store: ActionContext<any, any>, options: ICrudOptions) => {
  store.commit("IS_LOADING", true);
  store.commit("SET_SUCCESS", false);
  return await axios
    .put(`theory-courses/${options.id}/students`, options.data)
    .then((response: AxiosResponse) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", error.response?.data);
    });
};
export const bookTheoryCourseModules = async ({ commit }: ActionContext<any, any>, options: ICrudOptions) => {
  commit("SET_SUCCESS", false);
  commit("SET_ERROR", false);

  const theoryCourseId = options.id;
  const data = options.data;
  return await axios
    .post(`${URL}/${theoryCourseId}/book-module`, data)
    .then((res: any) => {
      commit("SET_SUCCESS", true);
      Vue.toasted.success(String(i18n.t("messages.operation_successful")));
      return res;
    })
    .catch((er: any) => {
      commit("SET_ERROR", true);
      return er;
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  findCoursesAndTheoryLessons,
  updateTheoryStudents,
  del,
  findUpcomingTheoryCourses,
  bookTheoryCourseModules,
};
