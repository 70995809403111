import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
  SET_SELECTED_ROW_NAME,
} from "@/store/Crud/functions/mutations";

import {
  IS_AVATAR_LOADING,
  SET_AVATAR_ERROR,
  SET_AVATAR_SUCCESS,
  SET_DATA_EXAM,
  SET_DATA_LIST_BY_COURSE,
  SET_DATA_EXAM_COST_BEARER,
  IS_LOADING_COST_BEARER,
  SET_DATA_ITEM_COST_BEARER,
  SET_SEND_PASSWORD_CHANGE_SUCCESS,
  SET_SEND_PASSWORD_CHANGE_ERROR,
  SET_SEND_PASSWORD_CHANGE_LOADING,
  SET_STUDENT_EMAILS,
  SET_STUDENT_EMAILS_ERROR,
  SET_STUDENT_EMAILS_LOADING,
  SET_DATA_LIST_BY_LIST,
} from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  SET_SELECTED_ROW_NAME,

  [SET_DATA_EXAM](state: State, exams: Array<any>) {
    Object.assign(state, { exams });
  },

  [IS_AVATAR_LOADING](state: State, avatarIsLoading: boolean) {
    Object.assign(state, { avatarIsLoading });
  },

  [SET_AVATAR_SUCCESS](state: State, avatarSuccess: any) {
    Object.assign(state, { avatarSuccess });
  },

  [SET_AVATAR_ERROR](state: State, avatarError: any) {
    Object.assign(state, { avatarError });
  },

  [SET_DATA_LIST_BY_COURSE](state: State, studentsByCourse: any) {
    Object.assign(state, { studentsByCourse });
  },

  [SET_DATA_EXAM_COST_BEARER](state: State, examsCostBearer: Array<any>) {
    Object.assign(state, { examsCostBearer });
  },

  [SET_DATA_ITEM_COST_BEARER](state: State, dataItemCostBearer: Record<string, any>) {
    Object.assign(state, { dataItemCostBearer });
  },

  [IS_LOADING_COST_BEARER](state: State, isLoadingCostBearer: boolean) {
    Object.assign(state, { isLoadingCostBearer });
  },

  [SET_SEND_PASSWORD_CHANGE_SUCCESS](state: State, sendPasswordChangeSuccess: boolean) {
    Object.assign(state, { sendPasswordChangeSuccess });
  },
  [SET_SEND_PASSWORD_CHANGE_ERROR](state: State, sendPasswordChangeError: Record<any, any> | null) {
    Object.assign(state, { sendPasswordChangeError });
  },
  [SET_SEND_PASSWORD_CHANGE_LOADING](state: State, sendPasswordChangeLoading: boolean) {
    Object.assign(state, { sendPasswordChangeLoading });
  },

  [SET_STUDENT_EMAILS](state: State, studentEmails: Array<any>) {
    Object.assign(state, { studentEmails });
  },
  [SET_STUDENT_EMAILS_LOADING](state: State, studentEmailsLoading: boolean) {
    Object.assign(state, { studentEmailsLoading });
  },
  [SET_STUDENT_EMAILS_ERROR](state: State, studentEmailsError: Record<any, any> | null) {
    Object.assign(state, { studentEmailsError });
  },

  [SET_DATA_LIST_BY_LIST](state: State, studentsByList: any) {
    Object.assign(state, { studentsByList });
  },
  SET_SELECTED_STUDENT(state: State, student: any) {
    Object.assign(state, { selectedStudent: student });
  },
};
