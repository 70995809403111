import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import CrudService from "@/services/CrudService";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { AxiosError, AxiosResponse } from "axios";
import { IS_LOADING, SET_DATA_ITEM, SET_ERROR, SET_SUCCESS } from "./types";

const crudService: CrudService = new CrudService();

const findOne = async (store: ActionContext<State<any>, any>, options: ICrudOptions) => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  store.commit(SET_ERROR, null);
  return await crudService
    .findOne(options)
    .then((response: AxiosResponse) => {
      store.commit(SET_DATA_ITEM, response.data);
      store.commit(SET_SUCCESS, true);
    })
    .catch((error: AxiosError) => {
      store.commit(SET_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};

export default <ActionTree<State<any>, any>>{
  findOne,
};
