import { CrudState } from "@/store/Crud/State";
import { IDocument } from "@/interfaces/IDocument";

export class State extends CrudState {
  documentsInProcessing: Array<IDocument> = [];
  documentsInProcessingError: Record<any, any> | null = null;
  documentsInProcessingSuccess = false;
  documentsInProcessingLoading = false;

  attachDocumentsInProcessingError: Record<any, any> | null = null;
  attachDocumentsInProcessingSuccess = false;
  attachDocumentsInProcessingLoading = false;

  documentsInProcessingDeleteError: Record<any, any> | null = null;
  documentsInProcessingDeleteSuccess = false;
  documentsInProcessingDeleteLoading = false;
}
