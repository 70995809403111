import { GetterTree } from "vuex";
import { State } from "./state";
import { IMainTab } from "@/interfaces/IMainTab";

export function getTabs(state: State): any {
  return state.tabs;
}

export function getActive(state: State): any {
  return state.active;
}

export function getCustomLabel(state: State): any {
  return state.customLabel;
}

export function getTabsHistory(state: State): Array<IMainTab> {
  return state.tabsHistory;
}

export default <GetterTree<State, any>>{
  getActive,
  getTabs,
  getCustomLabel,
  getTabsHistory,
};
