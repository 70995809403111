import { State } from "./state";
import { GetterTree } from "vuex";
import { getDataItem, getDataList, getTotal } from "@/store/AccountsConfig/getters";
export const getAbibaroDataItem = (state: State) => {
  return state.abibaroDataItem;
};
export const getAbibaroDataList = (state: State) => {
  return state.abibaroDataList;
};
export const getAbibaroLicenseClasses = (state: State) => {
  return state.abibaroLicenseClasses;
};

export const getAbibaroConcepts = (state: State) => {
  return state.abibaroConcepts;
};
export const getAbibaroRegions = (state: State) => {
  return state.abibaroRegions;
};
export const getSuccess = (state: State) => {
  return state.success;
};
export const getError = (state: State) => {
  return state.error;
};
export const getIsLoading = (state: State) => {
  return state.isLoading;
};

export default <GetterTree<State, any>>{
  getAbibaroDataList,
  getAbibaroLicenseClasses,
  getAbibaroDataItem,
  getAbibaroConcepts,
  getAbibaroRegions,
  getSuccess,
  getError,
  getIsLoading,
};
