import i18n from "@/i18n";
import { MODULE_THEORY } from "@/constants/Permissions";

export default [
  {
    path: "/theory",
    name: "Theory",
    meta: {
      title: String(i18n.t("sidebar.theory_lessons")),
      trans: "sidebar.theory_lessons",
      permissions: [MODULE_THEORY],
    },
    component: () => import(/* webpackChunkName: "theory" */ "@/views/Theory/Theory.vue"),
  },
];
