import i18n from "@/i18n";
import { MODULE_AUTOMATIC_PAYMENTS } from "@/constants/Permissions";

export default [
  {
    path: "/automatic-payments-overview",
    name: "AutomaticPaymentsOverview",
    meta: {
      title: String(i18n.t("sidebar.automatic_payments_overview")),
      trans: "sidebar.automatic_payments_overview",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () =>
      import(
        // eslint-disable-next-line max-len
        /* webpackChunkName: "automatic-payments-overview" */ "@/views/AutomaticPaymentsOverview/AutomaticPaymentsOverview.vue"
      ),
  },
  {
    path: "/automatic-payments-classic-pay",
    name: "ClassicPay",
    meta: {
      title: String(i18n.t("sidebar.automatic_payments_classic_pay")),
      trans: "sidebar.automatic_payments_classic_pay",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () => import(/* webpackChunkName: "automatic-payments-classic-pay" */ "@/views/AutomaticPaymentsOverview/ClassicPay.vue"),
  },
  {
    path: "/payment-request",
    name: "PaymentRequest",
    meta: {
      title: String(i18n.t("automatic_payment.payment_request")),
      trans: "automatic_payment.payment_request",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () => import(/* webpackChunkName: "payment-request" */ "@/views/AutomaticPaymentsOverview/PaymentRequest.vue"),
  },
  {
    path: "/automatic-payment-triggers",
    name: "AutomaticPaymentTriggers",
    meta: {
      title: String(i18n.tc("sidebar.automatic_payments_triggers", 1)),
      trans: "sidebar.automatic_payments_trigger",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () => import(/* webpackChunkName: "automatic-payments-triggers" */ "@/views/AutomaticPaymentsOverview/AutomaticPaymentTriggers.vue"),
  },
  {
    path: "/automatic-payments-live-pay",
    name: "LivePay",
    meta: {
      title: String(i18n.t("sidebar.automatic_payments_live_pay")),
      trans: "sidebar.automatic_payments_live_pay",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () => import(/* webpackChunkName: "automatic-payments-live-pay" */ "@/views/AutomaticPaymentsOverview/LivePay.vue"),
  },
  {
    path: "/automatic-payments-flash-pay",
    name: "FlashPay",
    meta: {
      title: String(i18n.t("sidebar.automatic_payments_flash_pay")),
      trans: "sidebar.automatic_payments_flash_pay",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () => import(/* webpackChunkName: "automatic-payments-flash-pay" */ "@/views/AutomaticPaymentsOverview/FlashPay.vue"),
  },
  {
    path: "/automatic-payments-payment-documents",
    name: "PaymentDocuments",
    meta: {
      title: String(i18n.t("sidebar.automatic_payments_payment_documents")),
      trans: "sidebar.automatic_payments_payment_documents",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () => import(/* webpackChunkName: "automatic-payments-payment-documents" */ "@/views/AutomaticPaymentsOverview/PaymentDocuments.vue"),
  },
  {
    path: "/automatic-payments-student-documents-history",
    name: "StudentDocumentsHistory",
    meta: {
      title: String(i18n.t("general.history")),
      trans: "general.history",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () =>
      import(/* webpackChunkName: "automatic-payments-student-documents-history" */ "@/views/AutomaticPaymentsOverview/StudentDocumentsHistory.vue"),
  },
  {
    path: "/partial-invoice",
    name: "PartialInvoice",
    meta: {
      title: String(i18n.t("automatic_payment.create_partial_invoice_short")),
      trans: "automatic_payment.create_partial_invoice_short",
      permissions: [MODULE_AUTOMATIC_PAYMENTS],
    },
    component: () => import(/* webpackChunkName: "partial-invoice" */ "@/views/AutomaticPaymentsOverview/PartialInvoice.vue"),
  },
];
