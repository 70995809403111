import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import {
  IS_LOADING,
  SET_ERROR,
  SET_DATA_LIST,
  SET_DATA_EXAM,
  SET_SUCCESS,
  SET_AVATAR_SUCCESS,
  IS_AVATAR_LOADING,
  SET_AVATAR_ERROR,
  SET_DATA_ITEM_COST_BEARER,
  SET_DATA_EXAM_COST_BEARER,
  IS_LOADING_COST_BEARER,
  SET_SEND_PASSWORD_CHANGE_SUCCESS,
  SET_SEND_PASSWORD_CHANGE_ERROR,
  SET_SEND_PASSWORD_CHANGE_LOADING,
  SET_STUDENT_EMAILS,
  SET_STUDENT_EMAILS_ERROR,
  SET_STUDENT_EMAILS_LOADING,
  SET_TOTAL,
  SET_DATA_LIST_BY_LIST,
} from "./types";
import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { create, findOne, update, filter, findAll, setSelectedRowName } from "@/store/Crud/functions/actions";
// @ts-ignore
import queryString from "query-string";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import i18n from "@/i18n";

const URL = "/students";
const messageDispatcher = new MessageDispatcher();

export const allowUserLogin = async (store: ActionContext<any, any>, options: any) => {
  store.commit("IS_LOADING", true);
  store.commit("SET_SUCCESS", false);
  return await axios
    .put(`students/${options.id}/change-drive-buzz-login-allowed?allowed=${options.allowed}`)
    .then((response: AxiosResponse) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_SUCCESS", true);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", error.response?.data);
    });
};

export const list = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL}/student-list`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data.data);
      store.commit(SET_TOTAL, data.total);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const studentsByList = async (store: any, filter: any = {}): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .post(`${URL}/student-list`, filter)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST_BY_LIST, data.data);
      store.commit(SET_TOTAL, data.total);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const uploadAvatar = async (store: any, data: any): Promise<void> => {
  store.commit(IS_AVATAR_LOADING, true);
  store.commit(SET_AVATAR_SUCCESS, false);
  return await axios
    .post(`${URL}/avatar`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      store.commit(IS_AVATAR_LOADING, false);
      store.commit(SET_AVATAR_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_AVATAR_LOADING, false);
      store.commit(SET_AVATAR_ERROR, response.data);
    });
};

export const findExamAndEducationByStudentId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/exam-and-education/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_EXAM, data);
      return data;
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findExamAndEducationByStudentIdCostBearer = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING_COST_BEARER, true);
  return await axios
    .get(`${URL}/exam-and-education/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING_COST_BEARER, false);
      store.commit(SET_DATA_EXAM_COST_BEARER, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING_COST_BEARER, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findStudentByIdCostBearer = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING_COST_BEARER, true);
  return await axios
    .get(`${URL}/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING_COST_BEARER, false);
      store.commit(SET_DATA_ITEM_COST_BEARER, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING_COST_BEARER, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findByInstructor = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}/by-instructor/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findVisibleForCurrentUser = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}/visible-for-current-user`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findStudentsByTheoryCourse = async (store: ActionContext<any, any>, id: number) => {
  store.commit("IS_LOADING", true);
  return await axios
    .get(`theory-courses/${id}/students`)
    .then((response: AxiosResponse) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_DATA_LIST_BY_COURSE", response.data);
    })
    .catch((error: AxiosError) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", error.response?.data);
    });
};

export const sendPasswordChange = async (store: ActionContext<any, any>, studentEmail: string) => {
  store.commit(SET_SEND_PASSWORD_CHANGE_SUCCESS, false);
  store.commit(SET_SEND_PASSWORD_CHANGE_LOADING, true);
  store.commit(SET_SEND_PASSWORD_CHANGE_ERROR, null);
  return await axios
    .post(`request-password-change/from-fsc?studentEmail=${encodeURIComponent(studentEmail)}`)
    .then(() => {
      store.commit(SET_SEND_PASSWORD_CHANGE_SUCCESS, true);
      messageDispatcher.dispatchSuccessMessage(store, String(i18n.t("messages.sed_success")));
    })
    .catch((error: AxiosError) => {
      store.commit(SET_SEND_PASSWORD_CHANGE_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(SET_SEND_PASSWORD_CHANGE_LOADING, false);
    });
};

export const archiveUnArchiveStudent = async (store: ActionContext<any, any>, options: any) => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios
    .post(`${URL}/archive?id=${options.id}&archived=${options.archived}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      messageDispatcher.dispatchSuccessMessage(store, String(i18n.t("students.operation_success")));
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findStudentEmails = async (store: any): Promise<void> => {
  store.commit(SET_STUDENT_EMAILS_LOADING, true);
  return await axios
    .get(`${URL}/all-with-email`)
    .then(({ data }) => {
      store.commit(SET_STUDENT_EMAILS_LOADING, false);
      store.commit(SET_STUDENT_EMAILS, data);
      return data;
    })
    .catch(({ response }) => {
      store.commit(SET_STUDENT_EMAILS_LOADING, false);
      store.commit(SET_STUDENT_EMAILS_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  findAll,
  update,
  list,
  uploadAvatar,
  findExamAndEducationByStudentId,
  findByInstructor,
  findVisibleForCurrentUser,
  findStudentsByTheoryCourse,
  findExamAndEducationByStudentIdCostBearer,
  findStudentByIdCostBearer,
  setSelectedRowName,
  sendPasswordChange,
  allowUserLogin,
  archiveUnArchiveStudent,
  findStudentEmails,
  studentsByList,
};
