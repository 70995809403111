export class State {
  error: Record<string, any> = {};
  success: Record<string, any> = {};
  isLoading = false;
  abibaroDataItem: Record<string, any> = {};
  abibaroDataList: Array<any> = [];
  abibaroLicenseClasses: Array<any> = [];
  abibaroConcepts: Array<any> = [];
  abibaroRegions: Array<any> = [];
}
