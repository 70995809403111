import { CrudState } from "@/store/Crud/State";
import { IPaymentWorkflowStudent } from "@/interfaces/IPaymentWorkflowStudent";

export class PaymentWorkflowState extends CrudState {
  studentList = [];
  studentListError = null;
  studentListSuccess = null;
  studentListLoading = false;
  studentListTotal = 0;

  overviewInfo: any = {};
  overviewInfoError = null;
  overviewInfoSuccess = null;
  overviewInfoLoading = false;

  cancelInvoice: any = null;
  cancelInvoiceError: Record<any, any> | null = null;
  cancelInvoiceSuccess = false;
  cancelInvoiceLoading = false;

  costBearerStudentList: Array<IPaymentWorkflowStudent> = [];
  costBearerStudentListError: Record<any, any> | null = null;
  costBearerStudentListSuccess = false;
  costBearerStudentListLoading = false;
}
