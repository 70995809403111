import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_LIST, SET_DATA_ITEM_LOADING, SET_TOTAL } from "@/store/Crud/functions/mutations";
import {
  SET_STUDENT_LIST_SUCCESS,
  SET_STUDENT_LIST_ERROR,
  SET_STUDENT_LIST_LOADING,
  SET_STUDENT_LIST,
  SET_OVERVIEW_INFO_ERROR,
  SET_OVERVIEW_INFO_SUCCESS,
  SET_OVERVIEW_INFO_LOADING,
  SET_OVERVIEW_INFO,
  SET_STUDENT_TOTAL,
} from "@/store/PaymentWorkflow/mutations";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_STUDENT_LIST_SUCCESS,
  SET_STUDENT_LIST_ERROR,
  SET_STUDENT_LIST_LOADING,
  SET_STUDENT_LIST,
  SET_OVERVIEW_INFO_ERROR,
  SET_OVERVIEW_INFO_SUCCESS,
  SET_OVERVIEW_INFO_LOADING,
  SET_OVERVIEW_INFO,
  SET_DATA_ITEM_LOADING,
  SET_STUDENT_TOTAL,
};
