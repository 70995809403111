import axios from "@/utils/axios";
import { IS_LOADING, SET_DATA_LIST, SET_ERROR } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";

import { create, filter, findOne, update } from "@/store/Crud/functions/actions";

// const URL = "/billing-address";
//
// /**
//  * @param store
//  * @param data
//  * @returns {Promise<void>}
//  */
// export const create = async (
//   store: any,
//   data: Record<string, any>
// ): Promise<void> => {
//   store.commit(IS_LOADING, true);
//   store.commit(SET_SUCCESS, null);
//   return await axios
//     .post(URL, data)
//     .then(({ data }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_SUCCESS, true);
//       store.commit(SET_DATA_ITEM, data);
//     })
//     .catch(({ response }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_ERROR, response.data);
//       store.commit(SET_SUCCESS, false);
//     });
// };
//
// export const filter = async (store: any, filter: any = {}): Promise<void> => {
//   store.commit(IS_LOADING, true);
//   return await axios
//     .post(`${URL}/`, filter)
//     .then(({ data }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_DATA_LIST, data.data);
//       store.commit(SET_TOTAL, data.total);
//     })
//     .catch(({ response }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_ERROR, response.data);
//     });
// };
//
// export const findOne = async (store: any, id: number): Promise<void> => {
//   store.commit(IS_LOADING, true);
//   return await axios
//     .get(`${URL}/${id}`)
//     .then(({ data }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_DATA_ITEM, data);
//     })
//     .catch(({ response }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_ERROR, response.data);
//     });
// };
//
// export const update = async (store: any, data: any): Promise<void> => {
//   store.commit(IS_LOADING, true);
//   store.commit(SET_SUCCESS, null);
//   return await axios
//     .put(`${URL}/${data.id}`, data)
//     .then(({ data }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_SUCCESS, true);
//       store.commit(SET_DATA_ITEM, data);
//     })
//     .catch(({ response }) => {
//       store.commit(IS_LOADING, false);
//       store.commit(SET_ERROR, response.data);
//       store.commit(SET_SUCCESS, false);
//     });
// };
//
export const byStudentEducationId = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/byStudentEducationId/${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  byStudentEducationId,
};
