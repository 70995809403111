import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_DATA_ID, SET_DATA_ITEM, SET_DATA_LIST, SET_ERROR, SET_SUCCESS, SET_TOTAL } from "@/store/Crud/functions/mutations";

import { SET_WRONG_ANSWER } from "./types";

export function SET_STATS(state: State, stats: any) {
  state.stats = stats;
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_STATS,

  [SET_WRONG_ANSWER](state: State, wrongAnswer: Record<string, any>) {
    Object.assign(state, { wrongAnswer });
  },
};
