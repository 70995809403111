export function findById(list: Array<any>, id: any): any {
  return list.find((s) => s.id == id);
}

export function findByProp(list: Array<any>, propValue: any, propName: string): any {
  return list.find((s) => s[propName] == propValue);
}

export function addObject(list: Array<any>, object: any, allowDuplicates = false): void {
  if (allowDuplicates) {
    list.push(object);
    return;
  }
  const objectAlreadyParticipant = list.find((s) => s.id == object.id);
  if (!objectAlreadyParticipant) {
    list.push(object);
  }
}

export function removeObject(list: Array<any>, object: any): void {
  const participantIndex = list.findIndex((s) => s.id == object.id);
  if (participantIndex > -1) {
    list.splice(participantIndex, 1);
  }
}

export function removeObjectByProp(list: Array<any>, object: any, propName: string): void {
  const participantIndex = list.findIndex((s) => s[propName] == object[propName]);
  if (participantIndex > -1) {
    list.splice(participantIndex, 1);
  }
}

export function removeObjectFromTableList(list: Array<any>, object: any): void {
  const participantIndex = list.findIndex((s) => s.id == object.item.id);
  if (participantIndex > -1) {
    list.splice(participantIndex, 1);
  }
}

export function filterList(list: Array<any>, searchTerm: string, searchProps: Array<string>): Array<any> {
  return list.filter((object) => {
    if (!searchTerm) {
      return object;
    }
    for (const searchProp of searchProps) {
      if (!object[searchProp]) {
        continue;
      }
      const termFound: boolean = object[searchProp].toLowerCase().indexOf(searchTerm.trim().toLowerCase()) > -1;
      if (termFound) {
        return true;
      }
    }
    return false;
  });
}
