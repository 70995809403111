import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import { SET_THEORY_QUESTION, SET_WRONG_ANSWER } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_THEORY_QUESTION](state: State, theoryQuestion: Record<string, any>) {
    Object.assign(state, { theoryQuestion });
  },

  [SET_WRONG_ANSWER](state: State, wrongAnswer: Record<string, any>) {
    Object.assign(state, { wrongAnswer });
  },
};
