import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import VueRouteMiddleware from "vue-route-middleware";
import AuthMiddleware from "@/middlewares/AuthMiddleware";
import GuestMiddleware from "@/middlewares/GuestMiddleware";
import Todo from "./Todo";
import AutomaticPaymentsOverview from "./AutomaticPaymentsOverview";
import Instructor from "./Instructor";
import Student from "./Student";
import Vehicle from "./Vehicle";
import Scheduler from "./Scheduler";
import Branch from "./Branch";
import Configuration from "./Configuration";
import Role from "./Role";
import MasterData from "./MasterData";
import Product from "./Product";
import Accounting from "./Accounting";
import PriceList from "./PriceList";
import PracticalExam from "@/router/PracticalExam";
import TheoryExam from "./TheoryExam";
import Theory from "./Theory";
import CostBearers from "./CostBearers";
import Report from "./Report";
import Communication from "./Communication";
import PersoFix from "./PersoFix";
import Dashboard from "./Dashboard";
import Mandate from "./Mandate";
import Pulse from "./Pulse";
import Gocardless from "@/router/Gocardless";
import i18n from "@/i18n";
import { MODULE_CUSTOMERS, MODULE_CUSTOMERS_WRITE } from "@/constants/Permissions";
import AbibaroMiddleware from "@/middlewares/AbibaroMiddleware";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    children: [
      ...Todo,
      ...Instructor,
      ...Student,
      ...Vehicle,
      ...Scheduler,
      ...Branch,
      ...Role,
      ...Configuration,
      ...MasterData,
      ...Product,
      ...Accounting,
      ...PriceList,
      ...PracticalExam,
      ...TheoryExam,
      ...Theory,
      ...AutomaticPaymentsOverview,
      ...CostBearers,
      ...Report,
      ...Communication,
      ...PersoFix,
      ...Dashboard,
      ...Mandate,
      ...Pulse,
      ...Gocardless,
    ],
    meta: {
      middleware: [AuthMiddleware],
      trans: "sidebar.home",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/logout",
    component: () => import("@/views/Auth/Logout.vue"),
    name: "Logout",
    meta: {
      middleware: [AuthMiddleware],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Auth/Login.vue"),
    meta: {
      middleware: [GuestMiddleware],
    },
  },
  {
    // This is triggered from the ADMIN UI
    path: "/support-user-token-login/:token",
    name: "SupportUserTokenLogin",
    component: () => import(/* webpackChunkName: "supportUserTokenAuth" */ "@/views/Auth/SupportUserTokenLogin.vue"),
    meta: {
      middleware: [GuestMiddleware]
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import(/* webpackChunkName: "forgotPassword" */ "@/views/Auth/ForgotPassword.vue"),
    meta: {
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import(/* webpackChunkName: "resetPassword" */ "@/views/Auth/ResetPassword.vue"),
    meta: {
      middleware: [GuestMiddleware],
    },
  },
  {
    path: "/fibu-fix",
    beforeEnter: () => {
      window.open("https://fahrschulcockpit.buhaportal.de/login/");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(VueRouteMiddleware());

export default router;
