import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

import { IS_LOADING_COST_BEARER, SET_DATA_ITEM_COST_BEARER } from "./types";

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,

  [SET_DATA_ITEM_COST_BEARER](state: State, dataItemCostBearer: Record<string, any>) {
    Object.assign(state, { dataItemCostBearer });
  },

  [IS_LOADING_COST_BEARER](state: State, isLoadingCostBearer: boolean) {
    Object.assign(state, { isLoadingCostBearer });
  },
};
