































import { Vue, Component, Prop } from "vue-property-decorator";
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/paste";
import "tinymce/plugins/wordcount";
import "./langs/de";
import Editor from "@tinymce/tinymce-vue";

@Component({
  components: {
    Editor,
  },
})
export default class Tinymce extends Vue {
  public name = "Tinymce";

  @Prop({ default: () => false })
  public disabled!: any;

  @Prop()
  public value!: any;

  @Prop()
  public customSelector?: any;

  onInput(value: any) {
    this.$emit("input", value);
  }
}
