import axios from "@/utils/axios";

export default class AbibaroService {
  public static checkAbibaroRegistration(): Promise<boolean> {
    return axios
      .get("financial-config/school-registered-in-abibaro")
      .then((res: any) => {
        const { data } = res;
        return data;
      })
      .catch((er: any) => false);
  }
}
