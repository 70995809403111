import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, update } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_WRONG_ANSWER } from "./types";
import axios from "@/utils/axios";

const URL = "/student-question-answers";

export async function getStats(store: ActionContext<any, any>, id: number): Promise<void> {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`/student-question-answers/stats-for-fsc?studentEducationId=${id}`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_STATS", data);
      store.commit(SET_SUCCESS, true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_STATS", null);
      store.commit(SET_ERROR, response.data);
    });
}

export const findWrongAnswerById = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/student/${options.studentId}/question/${options.theoryQuestionId}/last-wrong-answer`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_WRONG_ANSWER, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getStats,
  findWrongAnswerById,
};
