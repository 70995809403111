import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getFinishedEducationStateSuccess = (state: State) => state.finishedEducationStateSuccess;
export const getFinishedEducationStateError = (state: State) => state.finishedEducationStateError;
export const getFinishedEducationStateIsLoading = (state: State) => state.finishedEducationStateIsLoading;

export const getAutomaticPaymentStateSuccess = (state: State) => state.automaticPaymentStateSuccess;
export const getAutomaticPaymentStateError = (state: State) => state.automaticPaymentStateError;
export const getAutomaticPaymentStateIsLoading = (state: State) => state.automaticPaymentStateIsLoading;

export const getQuestionVersion = (state: State) => state.questionVersion;
export const getDriveBuzz = (state: State) => state.driveBuzzEnabled;

export const getDataItemCostBearer = (state: State) => state.dataItemCostBearer;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getFinishedEducationStateSuccess,
  getFinishedEducationStateError,
  getFinishedEducationStateIsLoading,
  getAutomaticPaymentStateSuccess,
  getAutomaticPaymentStateError,
  getAutomaticPaymentStateIsLoading,
  getQuestionVersion,
  getDriveBuzz,
  getDataItemCostBearer,
};
