import i18n from "@/i18n";
import { MODULE_EXAM } from "@/constants/Permissions";

export default [
  {
    path: "/theory-exam",
    name: "TheoryExam",
    meta: {
      title: String(i18n.t("sidebar.theory_exams")),
      trans: "sidebar.theory_exams",
      permissions: [MODULE_EXAM],
    },
    component: () => import(/* webpackChunkName: "theory-exam" */ "@/views/Exam/TheoryExam/TheoryExam.vue"),
  },
];
