import i18n from "@/i18n";

export default [
  {
    path: "/perso-fix",
    name: "PersoFix",
    meta: {
      title: String(i18n.t("accounting.perso")),
      trans: "accounting.perso",
    },
    component: () => import(/* webpackChunkName: "accounting" */ "@/views/Accounting/PersoFix.vue"),
  },
];
