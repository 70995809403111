import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IS_LOADING, SET_BALANCE_OVERVIEW, SET_ERROR } from "./types";

const URL = "payments";

const balanceOverview = async (store: ActionContext<any, any>): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/balance-overview`)
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_BALANCE_OVERVIEW, response.data);
    })
    .catch((error: AxiosError) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, error.response?.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  balanceOverview,
};
