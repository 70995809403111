var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Editor',{attrs:{"disabled":_vm.disabled,"init":{
    height: 335,
    branding: false,
    language: 'de',
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste wordcount' ],
    toolbar:
      'fontselect | helvetica | roboto | courier new | times new roman | \
       formatselect | bold italic strikethrough forecolor backcolor | \
         link | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat',
    //content_style: 'body,p { font-family: helvetica; }',
    font_formats: 'Helvetica=helvetica;Roboto=roboto,sans-serif;Courier New=courier new,courier;Times New Roman=times new roman,times;',
    setup: function (editor) {
      editor.on('init', function () {
        this.execCommand('fontName', false, 'Helvetica');
      });
    },
  },"value":_vm.value},on:{"input":_vm.onInput}})}
var staticRenderFns = []

export { render, staticRenderFns }