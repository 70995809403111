import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_ITEM, SET_DATA_LIST, SET_CONCEPTS, SET_REGIONS, SET_LICENSE_CLASSES } from "./types";

export default <MutationTree<State>>{
  [IS_LOADING](state: State, isLoading: boolean) {
    Object.assign(state, { isLoading });
  },

  [SET_ERROR](state: State, error: Record<string, any>) {
    Object.assign(state, { error });
  },

  [SET_SUCCESS](state: State, success: Record<string, any>) {
    Object.assign(state, { success });
  },

  [SET_DATA_ITEM](state: State, abibaroDataItem: Record<string, any>) {
    Object.assign(state, { abibaroDataItem });
  },

  [SET_DATA_LIST](state: State, abibaroDataList: Record<string, any>) {
    Object.assign(state, { abibaroDataList });
  },

  [SET_LICENSE_CLASSES](state: State, abibaroLicenseClasses: number) {
    Object.assign(state, { abibaroLicenseClasses });
  },
  [SET_CONCEPTS](state: State, abibaroConcepts: number) {
    Object.assign(state, { abibaroConcepts });
  },
  [SET_REGIONS](state: State, abibaroRegions: number) {
    Object.assign(state, { abibaroRegions });
  },
};
