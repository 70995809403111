import { CrudState } from "@/store/Crud/State";
import { ILivePayMandate } from "@/interfaces/ILivePayMandate";

export class State extends CrudState {
  mandateRevokeLoading = false;
  mandateRevokeError: Record<any, any> | null = null;
  mandateRevokeSuccess = false;
  mandateRevokeResponse: ILivePayMandate | null = null;

  mandateByStudentEducationLoading = false;
  mandateByStudentEducationError: Record<any, any> | null = null;
  mandateByStudentEducationSuccess = false;
  mandateByStudentEducationResponse: ILivePayMandate | null = null;

  mandateByStudentLoading = false;
  mandateByStudentError: Record<any, any> | null = null;
  mandateByStudentSuccess = false;
  mandateByStudentResponse: Array<ILivePayMandate> = [];
}
